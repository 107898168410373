import { initializeApp } from "firebase/app";
import { getMessaging, isSupported, Messaging } from "firebase/messaging";
import { firebaseConfig } from "../shared/Constants/firebaseConfig";


// ✅ Initialize Firebase App
const app = initializeApp(firebaseConfig);

export async function getFirebaseMessaging(): Promise<Messaging | null> {
  try {
    const isMessagingSupported = await isSupported();
    if (!isMessagingSupported) {
      console.warn("Firebase Messaging is not supported in this browser.");
      return null;
    }

    const messagingInstance = getMessaging(app);
    console.log("Firebase Messaging Initialized:", messagingInstance);
    return messagingInstance;
  } catch (error) {
    console.error("Error initializing Firebase Messaging:", error);
    return null;
  }
}

export { app };
