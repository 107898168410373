import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { getAllNotifications, markNotificationAsRead } from "../../Api";
import TitleBar from "../../Components/TitleBar/TitleBar";
import TitleImage from '../../assets/images/about-us.png';
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";

const getRelativeTime = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInMs = now.getTime() - date.getTime();
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
  if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
  if (diffInHours < 24) return `${diffInHours} hours ago`;
  return `${diffInDays} days ago`;
};
interface NotificationItem {
    id: number;
    message: string;
    created_at: string; // ISO date string
    is_read: boolean;
  }
  

const AllNotifications: React.FC = () => {
  const breadcrumbs: string[] = ['Home', 'Notifications'];
  const title: string = 'All Notifications';
  const info: string = "";
  const imageUrl: string = TitleImage;
  const role = useSelector((state: RootState) => state.User.ActiveRole); // Get the active role from Redux state

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [prevPage, setPrevPage] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]); // Define the type for notifications
  const [notificationsList, setNotificationsList] = useState<NotificationItem[]>([]); // Define the type for notificationsList
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getNotificationParams = () => {
    if (role === "user") {
      return {
        // unread: false,
        storeNotification: false,
        userNotification: true,
      };
    } else if (role === "store") {
      return {
        // unread: false,
        storeNotification: true,
        userNotification: false,
      };
    }
    // Default case in case role is undefined
    return {
      unread: false,
      storeNotification: false,
      userNotification: true,
    };
  };

  const fetchNotifications = async (page: number = currentPage) => {
    setLoading(true);
    setError(null);
    try {
      const params = { ...getNotificationParams(), page };
      const data = await getAllNotifications(params);
      setNotifications(data.notification_list || []);
      setNextPage(data.next);
      setPrevPage(data.prev);
      setCurrentPage(page); // Persist the current page
    } catch (err) {
      setError("Failed to fetch notifications.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  const getPageNumberFromUrl = (url: string | null): number | null => {
    if (!url) return null;
    const match = url.match(/[?&]page=(\d+)/);
    if (match && match[1]) {
      return parseInt(match[1], 10);
    }
    return null;
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleNotificationClick = async (notificationId: number) => {
    console.log("n id", notificationId);
    try {
      await markNotificationAsRead(notificationId);
      setNotifications(prevNotifications =>
        prevNotifications.map(note =>
          note.id === notificationId ? { ...note, is_read: true } : note
        )
      );

    } catch (err: any) {
      if (err.response) {
        console.error("Error data:", err.response.data);
        // alert(`Server Error: ${err.response.data.message || "Something went wrong."}`);
      } else if (err.request) {
        console.error("No response:", err.request);
        // alert("Error: No response from server.");
      } else {
        console.error("Error setup:", err.message);
        // alert(`Request Setup Error: ${err.message}`);
      }
    }
  };
  
  
  
  return (
    <div className="about-us common-page">
      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <div className="container" style={{ paddingTop: "5rem", margin: "0 auto" }}>
        {loading && <p>Loading notifications...</p>}
        {/* {error &&  <div className="text-center">
                    <NoDataFound title={error} />
                </div>} */}
        {!loading && notifications.length === 0 &&  <NoDataFound title="No Notificatons" />}
        <ul style={{ listStyle: "none", padding: 0 }}>
          {notifications.map((note) => (
            <li
              key={note.id}
              className="notification-item"
              onClick={() => {
                if (!note.is_read) handleNotificationClick(note.id);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "1rem",
                borderBottom: note.is_read ? "2px solid #e0eefe" : "2px solid #fff",
                backgroundColor: note.is_read ? "#fff" : "#e0eefe",
              }}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: note.is_read ? "#e0eefe" : "#fff",
                  marginRight: "1rem",
                }}
              >
                <FontAwesomeIcon   icon={faBell}
                                    size="lg"
                                    style={{ color: "#007bff" }}/>
              </div>
              <div style={{ flex: 1 }}>
              <p
                        style={{
                          margin: 0,
                          fontSize:14,
                          fontWeight: note.is_read ? "medium" : "bold",
                        }}
                      >
                        {note.message}
                      </p>
                <span style={{ color: "#999", fontSize: "0.875rem" }}>
                  {getRelativeTime(note.created_at)}
                </span>
              </div>
              {!note.is_read && (
                      <span
                        style={{
                          minWidth:"0.6rem",
                          width: "0.6rem",
                          height: "0.6rem",
                          borderRadius: "100%",
                          backgroundColor: "var(--primary-color)",
                          marginLeft: "0.5rem",
                          marginRight:"1rem"
                        }}
                      ></span>
                    )}
            </li>
          ))}
        </ul>
        {/* Pagination Buttons */}
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem",marginBottom:"2rem" }}>
          <button
           onClick={() => {
            const prevPageNumber = getPageNumberFromUrl(prevPage);
            if (prevPageNumber) fetchNotifications(prevPageNumber);
          }}
          disabled={!prevPage}
            style={{
              backgroundColor: prevPage ? "#007BFF" : "#CCCCCC",
              color: "white",
              padding: "0.5rem 1rem",
              border: "none",
              fontFamily:"Montserrat",
              borderRadius: "4px",
              cursor: prevPage ? "pointer" : "not-allowed",
            }}
          >
            Previous
          </button>
          <button
           onClick={() => {
            const nextPageNumber = getPageNumberFromUrl(nextPage);
            if (nextPageNumber) fetchNotifications(nextPageNumber);
          }}
          disabled={!nextPage}
            style={{
              backgroundColor: nextPage ? "#007BFF" : "#CCCCCC",
              color: "white",
              padding: "0.5rem 1rem",
              border: "none",
              fontFamily:"Montserrat",
              borderRadius: "4px",
              cursor: nextPage ? "pointer" : "not-allowed",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllNotifications;
