import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image, Spinner, Table, Card, Modal, Tabs, Tab } from 'react-bootstrap';
import styles from './Account.style';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/about-us.png';
import Section from '../../Components/Section/Section';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';
import moment from 'moment';
import { getStoreAccountStatement, getStoreAccountStatementPdf, getStoreTransactionData } from '../../Api';
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
import noProduct from '../../assets/images/no-product.png';
import NoDataFound from '../../Components/NoDataFound/NoDataFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf, faL } from '@fortawesome/free-solid-svg-icons';
import FullScreenLoader from '../../Components/Modal/FullScreenLoader/FullScreenLoader';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface Order {
  order: string;
  order_details: string;
  item_name: string;
  unit: number;
  total: string;
  status: string;
  commission_name: string | null;
  uom: string | null;
  rate: string;
  store: string;
  percentage_amount: string | null
}

interface TransactionData {
  status: boolean;
  error: string | null;
  message: string;
  order_list: Order[];
  total_amount: number;
  total_amount_calculated: number;
  vat_rate: number;   // Make sure this is included
  vat_amount: number; // Make sure this is included
  price_paid_by_customer?: {
    product_price: number;
    delivery_fees_paid_by_customer: number;
  };
  total_commission_amount: number;
}

const Account: React.FC = () => {
  // const breadcrumbs: string[] = ['Home', 'Seller Earning'];
  const { t } = useTranslation();

  const breadcrumbs: string[] = [t("footer.home"), t("footer.sellerEarning")];

  const title: string = 'Seller Earning';
  const info: string = "";
  const imageUrl: string = TitleImage; // Corrected imageUrl



  const navigate = useNavigate()

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState<boolean>(false);
  const [transactionDataLoader, setTransactionDataLoader] = useState<boolean>(false)
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState<string>(moment().format("YYYY-MM-DD"));
  const [tempFromDate, setTempFromDate] = useState(""); // Temporary date for 'From'
  const [tempToDate, setTempToDate] = useState(""); // Temporary date for 'To'

  const [activeKey, setActiveKey] = useState('home'); // Track the active tab

  const [accountStatementData, setAccountStatementData] = useState<any>(null);
  const [orderReturnData, setOrderReturnData] = useState<any>(null);
  const [orderSuccessData, setOrderSuccessData] = useState<any>(null);
  const [saleUnderReturnWindow, setSaleUnderReturnWindow] = useState<any>(null);
  const [storeId, setStoreId] = useState<number | null>(null);
  // const getTodayDate = () => {
  //   const today = new Date();
  //   return today.toISOString().split('T')[0];
  // };

  // // Set default date on component mount
  // useEffect(() => {
  //   const today = getTodayDate();
  //   setFromDate(today);
  //   setToDate(today);
  //   const storedId = localStorage.getItem(STORAGE_CONSTANTS.STORE_ID);
  //   console.log("Store ID", storeId);
  //   if (storedId) {
  //     setStoreId(Number(storedId));
  //   }

  //   fetchAccountStatement();
  // }, []);

// Helper function to calculate the start of the week (Sunday)
const [weeks, setWeeks] = useState<string[]>([]); // List of week ranges
  const [selectedWeek, setSelectedWeek] = useState<string>(""); // User-selected week

// Helper function to calculate the start of the week (Sunday)
const getWeekStartDate = (date: Date): string => {
    const day = date.getDay();
    const diff = date.getDate() - day; // Adjust to Sunday (0 is Sunday)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(diff);
    return startOfWeek.toISOString().split("T")[0];
  };

// Helper function to calculate the end of the week (Saturday)
const getWeekEndDate = (date: Date): string => {
  const day = date.getDay();
  const diff = date.getDate() - day + 6; // Adjust to Saturday (6 is Saturday)
  const endOfWeek = new Date(date);
  endOfWeek.setDate(diff);
  return endOfWeek.toISOString().split("T")[0];
};

  // Helper function to get the week range (start and end dates)
  const getWeekRange = (date: Date): string => {
    const startOfWeek = getWeekStartDate(date);
    const endOfWeek = getWeekEndDate(date);
    return `${startOfWeek} to ${endOfWeek}`;
  };

  // Generate all past weeks up to today
  const generatePastWeeks = () => {
    const today = new Date();
    const startDate = new Date("2024-01-01"); // Adjust as needed (your application's start date)
    const weeksList: string[] = [];

    // Loop backward from today to the start date
    let currentWeek = new Date(today);
    while (currentWeek >= startDate) {
      weeksList.push(getWeekRange(currentWeek));
      currentWeek.setDate(currentWeek.getDate() - 7); // Move to the previous week
    }

    setWeeks(weeksList);
    setSelectedWeek(weeksList[0]); // Set the most recent week as the default
  };

  // Set default dates (current week) on page load
  useEffect(() => {
    const today = new Date();
    const from = getWeekStartDate(today);
    const to = getWeekEndDate(today);

    setFromDate(from);
    setToDate(to);

    generatePastWeeks(); // Populate the dropdown with past weeks
  }, []);

  // Handle week selection
  const handleWeekChange = (selectedRange: string) => {
    setSelectedWeek(selectedRange);

    // Split the range to get start and end dates
    const [from, to] = selectedRange.split(" to ");
    setFromDate(from);
    setToDate(to);
  };

  // Trigger API call whenever fromDate or toDate changes
  useEffect(() => {
    if (fromDate && toDate) {
      console.log("Triggering API with dates:", { fromDate, toDate });
      fetchAccountStatement(true);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    const storedId = localStorage.getItem(STORAGE_CONSTANTS.STORE_ID);
    console.log("Store ID", storeId);

    if (storedId) {
      setStoreId(Number(storedId));
    }
    window.scrollTo(0, 0);
  }, []);

  // console.log("Store ID", storeId);
  const fetchAccountStatement = async (generateStatement = true) => {
    const storeId = localStorage.getItem(STORAGE_CONSTANTS.STORE_ID);
    setLoading(true);  // Set loading state to true before fetching data

    try {
      const store_id = storeId;
      const from_date = fromDate;
      const till_date = toDate;// Use the store ID from your context or props if dynamic
      const data = await getStoreAccountStatement({ store_id, from_date, till_date,        generate_statement: generateStatement      });
      // console.log(data, "orderReturnData");
      setAccountStatementData(data); // Set the fetched data
      setOrderReturnData(data.transaction_data.order_return_data);
      setOrderSuccessData(data.transaction_data.order_success_data);
      setSaleUnderReturnWindow(data.transaction_data.sale_under_return_window);
    } catch (error) {
      console.error('Error fetching account statement:', error);
    } finally {
      setLoading(false); // Turn off loading state once data is fetched
    }
  };

  // Call fetchAccountStatement when 'Go' button is clicked
  const handleGoClick = () => {
    // Set the actual fromDate and toDate based on the temporary values
    setFromDate(tempFromDate);
    setToDate(tempToDate);

    // Call the API on button click with the actual values of fromDate and toDate
    fetchAccountStatement(false);
  };



  const handleDownload = async () => {

      const payload = {
        store_id: storeId,
        from_date: fromDate,
        till_date: toDate,
        pdf_download:true
      };
      try {
        setPdfLoading(true);
        const pdfUrl = await getStoreAccountStatementPdf(payload);
        if (pdfUrl) {
          // Fetch the PDF as a Blob
          const response = await fetch(pdfUrl);
          if (!response.ok) {
            throw new Error('Failed to download PDF');
          }
    
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          // Create a temporary link element and trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.download = 'account_statement.pdf'; 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        console.error('Error fetching account statement:', error);
      } finally {
        setPdfLoading(false); 
      }
    };
  

  // Price Paid By Customer
  const [showPricePaidModal, setShowPricePaidModal] = useState(false);
  const [transactionData, setTransactionData] = useState<TransactionData | null>(null);

  const fetchTransactionData = async (storeId: any, fromDate: string, tillDate: string, searchValue: string) => {
    setTransactionData(null);
    setTransactionDataLoader(true);
    setError(null); // Reset error state
    try {
      if (!storeId) { // Check that storeId is set
        console.error("Store ID is null. Please set a valid store ID.");
      }

      const data = await getStoreTransactionData(storeId, fromDate, tillDate, searchValue);
      setTransactionData(() => data); // Store the fetched data in state
      console.log(data); // Log the data for debugging
    } catch (error: any) {
      console.error('Error:', error);
      setError(() => 'Failed to fetch transaction data.'); // Set error state

      if (error?.status !== 404) {
        toast.error(error?.response?.data?.message ?? "Something went wrong");
      }
    } finally {
      setTransactionDataLoader(() => false); // Reset loading state
    }
  };

  const handleShowPricePaidModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'price_paid_by_customer');
      setShowPricePaidModal(true);
    } catch (error) {
      setShowPricePaidModal(() => false);
    }
  };
  const handleClosePricePaidModal = () => setShowPricePaidModal(false);



  // Delivery Fee Paid by Customers
  const [showDeliveryFeeModal, setShowDeliveryFeeModal] = useState(false);
  const handleShowDeliveryFeeModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'delivery_fee_paid_by_customer');
      setShowDeliveryFeeModal(true);
    } catch (error) {
      setShowDeliveryFeeModal(() => false);
    }
  };

  const handleCloseDeliveryFeeModal = () => setShowDeliveryFeeModal(false);

  // Total Price Paid by Customer to Seller 
  const [showTotalPricePaidModal, setShowTotalPricePaidModal] = useState(false);
  const handleShowTotalPricePaidModal = () => setShowTotalPricePaidModal(true);
  const handleCloseTotalPricePaidModal = () => setShowTotalPricePaidModal(false);

  // Flatform Commission
  const [showFlatformCommissionModal, setShowFlatformCommissionModal] = useState(false);

  const handleShowFlatformCommissionModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'platfrom_commission');
      setShowFlatformCommissionModal(true);
    } catch (error) {
      setShowFlatformCommissionModal(() => false);
    }
  };

  const handleCloseFlatformCommissionModal = () => setShowFlatformCommissionModal(false);

  // Transaction
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const handleShowTransactionModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'transaction_fees');
      setShowTransactionModal(true);
    } catch (error) {
      setShowTransactionModal(() => false);
    }
  };
  const handleCloseTransactionModal = () => setShowTransactionModal(false);

  // Other  Fee 1					

  const [showOtherFeeOneModal, setShowOtherFeeOneModal] = useState(false);
  const handleShowOtherFeeOneModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'handling_charges');
      setShowOtherFeeOneModal(true);
    } catch (error) {
      setShowOtherFeeOneModal(() => false);
    }
  };
  const handleCloseOtherFeeOneModal = () => setShowOtherFeeOneModal(false);

  // Other  Fee 2					
  const [showOtherFeeTwoModal, setShowOtherFeeTwoModal] = useState(false);
  const handleShowOtherFeeTwoModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'other_fees');
      setShowOtherFeeTwoModal(true);
    } catch (error) {
      setShowOtherFeeTwoModal(() => false);
    }
  };


  const handleCloseOtherFeeTwoModal = () => setShowOtherFeeTwoModal(false);

  // Delivery Fee						
  const [showPlatformDeliveryFeeModal, setShowPlatformDeliveryFeeModal] = useState(false);
  const handleShowPlatformDeliveryFeeModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'delivery_fees');
      setShowPlatformDeliveryFeeModal(true);
    } catch (error) {
      setShowPlatformDeliveryFeeModal(() => false);
    }
  };


  const handleClosePlatformDeliveryFeeModal = () => setShowPlatformDeliveryFeeModal(false);

  // Product Price - Under Return Window	
  const [showProductPriceUnderReturnWindowModal, setShowProductPriceUnderReturnWindowModal] = useState(false);
  const handleShowProductPriceUnderReturnWindowModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'sale_under_return_window');
      setShowProductPriceUnderReturnWindowModal(true);
    } catch (error) {
      setShowProductPriceUnderReturnWindowModal(() => false);
    }
  };

  const handleCloseProductPriceUnderReturnWindowModal = () => setShowProductPriceUnderReturnWindowModal(false);

  // Delivery Fee  - Under Return Window
  const [showDeliveryFeeUnderReturnWindowModal, setShowDeliveryFeeUnderReturnWindowModal] = useState(false);
  const handleShowDeliveryFeeUnderReturnWindowModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'sale_under_return_window_delivery');
      setShowDeliveryFeeUnderReturnWindowModal(true);
    } catch (error) {
      setShowDeliveryFeeUnderReturnWindowModal(() => false);
    }
  };


  const handleCloseDeliveryFeeUnderReturnWindowModal = () => setShowDeliveryFeeUnderReturnWindowModal(false);

  // Product Price - Return / Refund under processing
  const [showProductPriceReturnRefundUnderProcessingModal, setShowProductPriceReturnRefundUnderProcessinModal] = useState(false);
  const handleShowProductPriceReturnRefundUnderProcessinModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'sale_under_processing');
      setShowProductPriceReturnRefundUnderProcessinModal(true);
    } catch (error) {
      setShowProductPriceReturnRefundUnderProcessinModal(() => false);
    }
  };
  const handleCloseProductPriceReturnRefundUnderProcessinModal = () => setShowProductPriceReturnRefundUnderProcessinModal(false);

  // DeliveryFee  - Return / Refund under processing
  const [showDeliveryFeeReturnRefundUnderProcessingModal, setShowDeliveryFeeReturnRefundUnderProcessinModal] = useState(false);
  const handleShowDeliveryFeeReturnRefundUnderProcessinModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'sale_under_processing_delivery');
      setShowDeliveryFeeReturnRefundUnderProcessinModal(true);
    } catch (error) {
      setShowDeliveryFeeReturnRefundUnderProcessinModal(() => false);
    }
  };
  const handleCloseDeliveryFeeReturnRefundUnderProcessinModal = () => setShowDeliveryFeeReturnRefundUnderProcessinModal(false);

  // Refunded to Customer
  const [showRefundedToCustomerModal, setShowRefundedToCustomerModal] = useState(false);
  const handleShowRefundedToCustomerModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'refund_paid_to_customer');
      setShowRefundedToCustomerModal(true);
    } catch (error) {
      setShowRefundedToCustomerModal(() => false);
    }
  };

  const handleCloseRefundedToCustomerModal = () => setShowRefundedToCustomerModal(false);

  // Commission refunded on return
  const [showCommissionRefundedOnReturnModal, setShowCommissionRefundedOnReturnModal] = useState(false);
  const handleShowCommissionRefundedOnReturnModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'refund_platfrom_commission');
      setShowCommissionRefundedOnReturnModal(true);
    } catch (error) {
      setShowCommissionRefundedOnReturnModal(() => false);
    }
  };

  const handleCloseCommissionRefundedOnReturnModal = () => setShowCommissionRefundedOnReturnModal(false);

  // Transaction fee refunded on return
  const [showTransactionFeeRefundedOnReturnModal, setShowTransactionFeeRefundedOnReturnModal] = useState(false);
  const handleShowTransactionFeeRefundedOnReturnModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'refund_transaction_fees');
      setShowTransactionFeeRefundedOnReturnModal(true);
    } catch (error) {
      setShowTransactionFeeRefundedOnReturnModal(() => false);
    }
  };


  const handleCloseTransactionFeeRefundedOnReturnModal = () => setShowTransactionFeeRefundedOnReturnModal(false);

  // Other Fee1 refunded on return 
  const [showOtherFeeOneRefundedOnReturnModal, setShowOtherFeeOneRefundedOnReturnModal] = useState(false);
  const handleShowOtherFeeOneRefundedOnReturnModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'refund_handling_charges');
      setShowOtherFeeOneRefundedOnReturnModal(true);
    } catch (error) {
      setShowOtherFeeOneRefundedOnReturnModal(() => false);
    }
  };


  const handleCloseOtherFeeOneRefundedOnReturnModal = () => setShowOtherFeeOneRefundedOnReturnModal(false);

  // Other Fee2 refunded on return
  const [showOtherFeeTwoRefundedOnReturnModal, setShowOtherFeeTwoRefundedOnReturnModal] = useState(false);
  const handleShowOtherFeeTwoRefundedOnReturnModal = async () => {
    try {
      await fetchTransactionData(storeId, fromDate, toDate, 'refund_other_fees');
      setShowOtherFeeTwoRefundedOnReturnModal(true);
    } catch (error) {
      setShowOtherFeeTwoRefundedOnReturnModal(() => false);
    }
  };
  const handleCloseOtherFeeTwoRefundedOnReturnModal = () => setShowOtherFeeTwoRefundedOnReturnModal(false);

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const toggleRow = (index: number) => {
    setExpandedRows(prev =>
      prev.includes(index) ? prev.filter(row => row !== index) : [...prev, index]
    );
  };



  return (
    <div className="about-us common-page">

      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="aboutUs account" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
          {/* <Row>
          <Col>
          <Heading text="About The Company" alignment="left" />
          <p style={styles.text}>
          Welcome to <b>Sajilo Life,</b> where our mission is clear: <b>Makes Life Easier.</b> As a leading retail-first marketplace, we are committed to streamlining your shopping and service needs, delivering everything you require directly to your doorstep.
          </p>
          </Col>
         </Row> */}




          <Row className='card-row mb-5 seller-card-row'>
            {/* Total Sales Amount */}
            <Col md={3}>
              <Card className="text-center shadow-sm">
                <Card.Body>
                  <Card.Title>Total Sales</Card.Title>
                  <Card.Text>रु00.00</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Payout Pending Amount */}
            <Col md={3}>
              <Card className="text-center shadow-sm">
                <Card.Body>
                  <Card.Title>Payout Pending</Card.Title>
                  <Card.Text>रु00.00</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Payout Released Amount */}
            <Col md={3}>
              <Card className="text-center shadow-sm">
                <Card.Body>
                  <Card.Title>Payout Released</Card.Title>
                  <Card.Text>रु00.00</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Refund/Return Amount */}
            <Col md={3}>
              <Card className="text-center shadow-sm">
                <Card.Body>
                  <Card.Title>Refund/Return</Card.Title>
                  <Card.Text>रु00.00</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          
          <Row className='card-row shadow-sm card mx-1 date-card'>
            <Col className='px-0'>

              <Tabs
                      activeKey={activeKey}
                      onSelect={(k: string | null) => {
                        if (k !== null) {
                          setActiveKey(k); // Update active tab only if `k` is not null
                        }
                      }}
                defaultActiveKey="home"
                id="fill-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="home" title="Earnings by Week">
                  <Row className="justify-content-center p-4 mb-0">
                    <Col md={8}>
                      <div className="store-dates mb-store-dates">
                        <Form.Group className="d-flex align-items-center">
                          <Form.Label className="mb-0" style={{width:"25%"}}>Select Week</Form.Label>
                          <Form.Select
                            value={selectedWeek}
                            onChange={(e) => handleWeekChange(e.target.value)}
                          >
                            {weeks.map((week, index) => (
                              <option key={index} value={week}>
                                {week}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                      </div>

                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="profile" title="Earnings by Dates">
      <Row style={styles.card} className='p-4 mb-0'>
        <Col>
          <div className="store-dates mb-store-dates">
            From
            <input
              type="date"
              value={tempFromDate} // Use temporary state for 'From'
              onChange={(e) => setTempFromDate(e.target.value)} // Update temporary 'From' date
            />
            To
            <input
              type="date"
              value={tempToDate} // Use temporary state for 'To'
              onChange={(e) => setTempToDate(e.target.value)} // Update temporary 'To' date
            />
            <Button onClick={handleGoClick}>Go</Button> {/* Call handleGoClick on button click */}
          </div>
        </Col>
      </Row>
    </Tab>
              </Tabs>

            </Col>
          </Row>
          <Row className="account-pdf-download">
            <Col sm={5}>
            Download Statement Cycle <br></br>(Date : {fromDate} - {toDate})
            </Col>
            <Col sm={4}>
            {activeKey !== 'profile' && ( 
              <>
Statement Cycle Ref.: {accountStatementData ? accountStatementData.transaction_data.ac_statement_reference : "-"}
            </> )}
            </Col>
            <Col sm={2}>
            Payout Date : N/A
            </Col>
            <Col sm={1} style={{display:"flex",justifyContent:"flex-end"}}>
      <button onClick={handleDownload} disabled={pdfLoading}>
      {pdfLoading ? (
        <>
          <Spinner animation="border" size="sm" />
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faDownload as any} />
        </>
      )}
      </button>
      </Col>
          </Row>
          <Row>
            <Col>
            {loading ? (
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <Spinner animation="grow" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        ) : (
        accountStatementData ? (

                <div className="table-responsive">
                  <Table bordered className="account-main-table">
                    <thead>
                      <tr>
                        <th>Items Name</th>
                        <th>Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* First Row */}
                      <tr>
                        <td>

                          Previous Balance
                        </td>
                        <td>{accountStatementData.transaction_data.previous_balance}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => toggleRow(0)}
                            aria-controls="collapse-row-0"
                            aria-expanded={expandedRows.includes(0)}
                          >
                            {expandedRows.includes(0) ? '-' : '+'}
                          </Button>


                          Price paid by Customer

                        </td>
                        <td></td>
                        <td>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleShowTotalPricePaidModal();
                            }}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {accountStatementData.transaction_data.total_amount_calculated}
                          </a>
                        </td>
                      </tr>
                      {expandedRows.includes(0) && (
                        <tr id="collapse-row-0">
                          <td colSpan={3} className='p-0'>
                            <Table size="sm" className="mb-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowPricePaidModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >

                                      Product Price</a></td>
                                  <td>{accountStatementData.transaction_data.price_paid_by_customer.product_price}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowDeliveryFeeModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Delivery Fee paid by customer
                                    </a>
                                  </td>
                                  <td>{accountStatementData.transaction_data.price_paid_by_customer.delivery_fees_paid_by_customer}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}

                      {/* Second Row */}
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => toggleRow(1)}
                            aria-controls="collapse-row-1"
                            aria-expanded={expandedRows.includes(1)}
                          >
                            {expandedRows.includes(1) ? '-' : '+'}
                          </Button>
                          Sale under Return Window
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.sale_under_return_win_total} </td>
                      </tr>
                      {expandedRows.includes(1) && (
                        <tr id="collapse-row-1">
                          <td colSpan={3} className="p-0">
                            <Table size="sm" className="mb-0">
                              <tbody>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowProductPriceUnderReturnWindowModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Product Price - Under Return Window
                                    </a>
                                  </td>
                                  <td>{accountStatementData.transaction_data.sale_under_return_window.product_price}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowDeliveryFeeUnderReturnWindowModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Delivery Fee  - Under Return Window</a>
                                  </td>
                                  <td>{accountStatementData.transaction_data.sale_under_return_window.return_win_delivery_charges}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}

                      {/* Third Row */}
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => toggleRow(2)}
                            aria-controls="collapse-row-2"
                            aria-expanded={expandedRows.includes(2)}
                          >
                            {expandedRows.includes(2) ? '-' : '+'}
                          </Button>
                          Sale under Processing
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.sale_under_processing_total}</td>
                      </tr>
                      {expandedRows.includes(2) && (
                        <tr id="collapse-row-2">
                          <td colSpan={3} className='p-0'>
                            <Table size="sm" className='mb-0'>
                              <tbody>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowProductPriceReturnRefundUnderProcessinModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Product Price - Return / Refund under processing
                                    </a></td>
                                  <td>{accountStatementData.transaction_data.sale_under_processing.product_price}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowDeliveryFeeReturnRefundUnderProcessinModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Delivery Fee  - Return / Refund under processing</a></td>
                                  <td>{accountStatementData.transaction_data.sale_under_processing.sale_under_process_delivery_charges}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}


                      {/* Fourth Row */}
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => toggleRow(3)}
                            aria-controls="collapse-row-3"
                            aria-expanded={expandedRows.includes(3)}
                          >
                            {expandedRows.includes(3) ? '-' : '+'}
                          </Button>
                          Any other approved claim by seller
                        </td>
                        <td></td>
                        <td>0</td>
                      </tr>
                      {expandedRows.includes(3) && (
                        <tr id="collapse-row-3">
                          <td colSpan={3} className='p-0'>
                            <Table size="sm" className='mb-0'>
                              <tbody>
                                <tr>
                                  <td>Product damanged in transit</td>
                                  <td>0</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}

                      {/* Fifth Row */}
                      <tr>
                        <td>

                          Sub Total Price Paid by customer
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.sub_total_price_paid_by_customer}</td>
                      </tr>

                      {/* sixth Row */}
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => toggleRow(5)}
                            aria-controls="collapse-row-5"
                            aria-expanded={expandedRows.includes(5)}
                          >
                            {expandedRows.includes(5) ? '-' : '+'}
                          </Button>
                          Platform Fees (Including Tax)
                        </td>
                        <td></td>
                        <td style={{ backgroundColor: "#f7f7f7" }}>{accountStatementData.transaction_data.success_total_amount}</td>
                      </tr>
                      {expandedRows.includes(5) && (
                        <tr id="collapse-row-5">
                          <td colSpan={3} className='p-0'>
                            <Table size="sm" className='mb-0'>
                              <tbody>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowFlatformCommissionModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Platform Commission</a></td>

                                  <td>{accountStatementData.transaction_data.order_success_data.platform_category_margine}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowTransactionModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Transaction
                                    </a></td>

                                  <td>{accountStatementData.transaction_data.order_success_data.transaction_fees}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowOtherFeeOneModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Other Charges 1</a>
                                  </td>

                                  <td>{accountStatementData.transaction_data.order_success_data.handling_charges}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowOtherFeeTwoModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Other Charges 2</a>
                                  </td>

                                  <td>{accountStatementData.transaction_data.order_success_data.other_fees}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowPlatformDeliveryFeeModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Delivery Fee
                                    </a></td>

                                  <td>{accountStatementData.transaction_data.order_success_data.delivery_charges_paid}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Monthly Subscription</td>
                                  <td>{accountStatementData.transaction_data.order_success_data.monthly_subscription}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}

                      {/* Seventh Row */}
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => toggleRow(6)}
                            aria-controls="collapse-row-6"
                            aria-expanded={expandedRows.includes(6)}
                          >
                            {expandedRows.includes(6) ? '-' : '+'}
                          </Button>
                          Returns
                        </td>
                        <td></td>

                        <td style={{ backgroundColor: "#f7f7f7" }}>{accountStatementData.transaction_data.return_total_amount}</td>
                      </tr>
                      {expandedRows.includes(6) && (
                        <tr id="collapse-row-6">
                          <td colSpan={3} className='p-0'>
                            <Table size="sm" className='mb-0'>
                              <tbody>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowRefundedToCustomerModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Refunded to Customer
                                    </a></td>
                                  <td> {accountStatementData.transaction_data.order_return_data.product_price} </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowCommissionRefundedOnReturnModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Commission refunded on return</a></td>
                                  <td>{accountStatementData.transaction_data.order_return_data.platform_category_margine}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowTransactionFeeRefundedOnReturnModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Transaction fee refunded on return</a></td>
                                  <td>{accountStatementData.transaction_data.order_return_data.transaction_fees}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleShowOtherFeeOneRefundedOnReturnModal();
                                      }}
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >Other Fee 1 refunded on return
                                    </a>
                                  </td>
                                  <td>{accountStatementData.transaction_data.order_return_data.handling_charges}</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td><a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleShowOtherFeeTwoRefundedOnReturnModal();
                                    }}
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  >Other Fee 2 refunded on return</a>
                                  </td>
                                  <td>{accountStatementData.transaction_data.order_return_data.other_fees}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}


                      {/* Eighth Row */}
                      <tr>
                        <td>
                          <Button
                            variant="link"
                            onClick={() => toggleRow(7)}
                            aria-controls="collapse-row-7"
                            aria-expanded={expandedRows.includes(7)}
                          >
                            {expandedRows.includes(7) ? '-' : '+'}
                          </Button>
                          Withholding Sale Tax
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.with_holding_sale_tax_total}</td>
                      </tr>
                      {expandedRows.includes(7) && (
                        <tr id="collapse-row-7">
                          <td colSpan={3} className='p-0'>
                            <Table size="sm" className='mb-0'>
                              <tbody>
                                <tr>
                                  <td>Withholding Sale Tax</td>
                                  <td>{accountStatementData.transaction_data.with_holding_sale_tax}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                      {/* Nine  Row */}
                      <tr>
                        <td>

                          Sub Total Payable to Seller
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.sub_total_pay_to_seller}</td>
                      </tr>

                      {/* tenth Row */}
                      <tr>
                        <td>

                          COD Collected By Seller
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.total_cod_collect_by_seller}</td>
                      </tr>

                      {/* elevanth Row */}
                      <tr>
                        <td>
                          Recievable  by Seller
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.received_by_seller}</td>
                      </tr>

                      {/* 12th Row */}
                      <tr>
                        <td>
                          Payout Released [Date] [Ref]
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.payout_released}</td>
                      </tr>

                      {/* 13th Row */}
                      <tr>
                        <td>
                          Due
                        </td>
                        <td></td>
                        <td>{accountStatementData.transaction_data.due}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
               ) : (
                <NoDataFound title="No Earnings" />
              )
            )}
          </Col>
        </Row>
        </Container>
      </Section>
      {transactionData && (
        <Modal show={showPricePaidModal} onHide={handleClosePricePaidModal} className="sellerModal" centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Product Price Paid By Customers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Here are the details of the price paid by the customer:</p>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Order No</th>
                    <th>Order Details</th>
                    <th>Items Name</th>
                    <th>Unit</th>
                    <th>Rate</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionData.order_list.map((order: Order, index: number) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.rate}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>Total Product Price</td>
                    <td>{transactionData.total_amount}</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePricePaidModal}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      )}

      <Modal show={showDeliveryFeeModal} onHide={handleCloseDeliveryFeeModal} className="sellerModal" centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Fee Paid by Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Delivery Fee Paid by Customers:</p>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Item Name</th>
                  <th>Unit</th>

                  {/* <th>Status</th> */}
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Total</th>
                  {/* <th>Store</th> */}
                </tr>
              </thead>
              <tbody>
                {transactionData && transactionData.order_list && transactionData.order_list.length > 0 ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>

                      {/* <td>{order.status}</td> */}
                      <td>{order.commission_name}</td>
                      <td>{order.uom}</td>
                      <td>{order.rate}</td>
                      <td>{order.total}</td>
                      {/* <td>{order.store}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} style={{ textAlign: 'center' }}>No delivery fee data available.</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={7}><strong>Total Delivery Fee</strong></td>
                  <td colSpan={1}><strong>{transactionData ? transactionData.total_amount : ""}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>

      </Modal>

      <Modal show={showTotalPricePaidModal} onHide={handleCloseTotalPricePaidModal} className="sellerModal" centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Total Price Paid by Customer to Seller</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Total Price Paid by Customer to Seller:</p>
          {accountStatementData && accountStatementData.transaction_data && accountStatementData.transaction_data.price_paid_by_customer ? (
            <div className="table-responsive">
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Product Price</td>
                    <td>{accountStatementData.transaction_data.price_paid_by_customer.product_price}</td>
                  </tr>
                  <tr>
                    <td>Delivery Fee</td>
                    <td>{accountStatementData.transaction_data.price_paid_by_customer.delivery_fees_paid_by_customer}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Price Paid by Customer</strong></td>
                    <td><strong>{accountStatementData.transaction_data.total_amount_calculated}</strong></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No transaction details available.</p>
          )}

        </Modal.Body>
        {/* <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseTotalPricePaidModal}>
      Close
    </Button>
  </Modal.Footer> */}
      </Modal>

      <Modal
        show={showFlatformCommissionModal}
        onHide={handleCloseFlatformCommissionModal}
        className="sellerModal"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Platform Commission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Platform Commission:</p>

          {transactionData ? (
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Order No</th>
                    <th>Order Details</th>
                    <th>Items Name</th>
                    <th>Unit</th>
                    <th>UOM</th>
                    <th>Rate</th>
                    <th>Product Price Total</th>
                    <th>Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name || "N/A"}</td>
                      <td>{order.unit}</td>
                      <td>{order.uom || "N/A"}</td>
                      <td>{order.rate}%</td>
                      <td>{order.total}</td>
                      <td>{order?.percentage_amount}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={7}>
                      <strong>Platform Commission</strong>
                    </td>
                    <td>
                      <strong>
                        {transactionData.total_amount}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={7}>
                      <strong>VAT: {transactionData.vat_rate || 0}%</strong>
                    </td>
                    <td>
                      <strong>{(transactionData.vat_amount || 0).toFixed(2)}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={7}>
                      <strong>Total Platform Commission</strong>
                    </td>
                    <td>
                      <strong>{transactionData.total_commission_amount || 0}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No transaction data available.</p>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFlatformCommissionModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


      <Modal show={showTransactionModal} onHide={handleCloseTransactionModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Fee </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Transaction Fee:</p>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Unit</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {/* Check if transactionData exists */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name}</td>
                      <td>{order.uom}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Platform Commission row */}
                {transactionData && (
                  <>
                    <tr>
                      <td colSpan={9}>
                        <strong>Transaction Fee </strong>
                      </td>
                      <td>
                        <strong>
                          {transactionData.total_amount}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <strong>VAT: {transactionData.vat_rate || 0}%</strong>
                      </td>
                      <td>
                        <strong>{transactionData.vat_amount || 0}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <strong>Total Transaction Fee</strong>
                      </td>
                      <td>
                        <strong>{transactionData.total_commission_amount || 0}</strong>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTransactionModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showOtherFeeOneModal} onHide={handleCloseOtherFeeOneModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Other  Fee 1 </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Other  Fee 1	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Unit</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {/* Render order data only if transactionData and order_list are defined */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name}</td>
                      <td>{order.uom}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Transaction Fee, VAT, and Total Rows */}
                {transactionData && (
                  <>
                    <tr>
                      <td colSpan={9}>
                        <strong>Handling Charges Fee </strong>
                      </td>
                      <td>
                        <strong>
                          {transactionData.total_amount}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <strong>VAT: {transactionData.vat_rate || 0}%</strong>
                      </td>
                      <td>
                        <strong>{transactionData.vat_amount || 0}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <strong>Total Handling Charges Fee</strong>
                      </td>
                      <td>
                        <strong>{transactionData.total_commission_amount || 0}</strong>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>

          </div>


        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOtherFeeOneModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showOtherFeeTwoModal} onHide={handleCloseOtherFeeTwoModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Other  Fee 2 </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Other  Fee 2	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>

                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>No of Unit</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {/* Render order data only if transactionData and order_list are defined */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name}</td>
                      <td>{order.uom}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Transaction Fee, VAT, and Total Rows */}
                {transactionData && (
                  <>

                    <tr>
                      <td colSpan={9}>
                        <strong>Other Fees </strong>
                      </td>
                      <td>
                        <strong>
                          {transactionData.total_amount}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <strong>VAT: {transactionData.vat_rate || 0}%</strong>
                      </td>
                      <td>
                        <strong>{transactionData.vat_amount || 0}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9}>
                        <strong>Total Other Fees</strong>
                      </td>
                      <td>
                        <strong>{transactionData.total_commission_amount || 0}</strong>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOtherFeeTwoModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showPlatformDeliveryFeeModal} onHide={handleClosePlatformDeliveryFeeModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Fee </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Delivery Fee	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Item Name</th>
                  <th>Unit</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {transactionData && transactionData.order_list && transactionData.order_list.length > 0 ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name}</td>
                      <td>{order.uom}</td>
                      <td>{order.rate}</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} style={{ textAlign: 'center' }}>No delivery fee data available.</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={9}><strong>Total Delivery Fee</strong></td>
                  <td colSpan={1}><strong>{transactionData ? transactionData.total_amount : ""}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePlatformDeliveryFeeModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showProductPriceUnderReturnWindowModal} onHide={handleCloseProductPriceUnderReturnWindowModal} className="sellerModal" centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Product Price - Under Return Window </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Product Price - Under Return Window	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {transactionData && transactionData.order_list && transactionData.order_list.length > 0 ? (
                  transactionData.order_list.map((order) => (
                    <tr key={order.order}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.rate}</td>
                      <td>{order.total}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>No orders available.</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={5}><strong>Total Product Price Paid by Customer - On hold due to return period</strong></td>
                  <td><strong>{transactionData ? transactionData.total_amount : 0}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseProductPriceUnderReturnWindowModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showDeliveryFeeUnderReturnWindowModal} onHide={handleCloseDeliveryFeeUnderReturnWindowModal} className="sellerModal" centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Fee - Under Return Window</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Delivery Fee - Under Return Window:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>UOM</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {transactionData && transactionData.order_list && transactionData.order_list.length > 0 ? (
                  transactionData.order_list.map((order) => (
                    <tr key={order.order}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.uom}</td>
                      <td>{order.unit}</td>
                      <td>{order.rate}</td>
                      <td>{order.total}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No delivery fees available.</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={6}><strong>Total Delivery Fee - On hold due to return period</strong></td>
                  <td><strong>{transactionData ? transactionData.total_amount : 0}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {/* Uncomment if you want to include a footer */}
        {/* <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseDeliveryFeeUnderReturnWindowModal}>
      Close
    </Button>
  </Modal.Footer> */}
      </Modal>


      <Modal show={showProductPriceReturnRefundUnderProcessingModal} onHide={handleCloseProductPriceReturnRefundUnderProcessinModal} className="sellerModal" centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Product Price - Return / Refund under processing </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Product Price - Return / Refund under processing	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {transactionData && transactionData.order_list && transactionData.order_list.length > 0 ? (
                  transactionData.order_list.map((order) => (
                    <tr key={order.order}>
                      <td>{order.order}</td>
                      <td>{order.order_details || "N/A"}</td>
                      <td>{order.item_name || "Product"}</td>
                      <td>{order.unit || 0}</td>
                      <td>{order.rate || "0.00"}</td>
                      <td>{order.total || "0.00"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>No orders with returns in process.</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={5}><strong>Total of Return in Process</strong></td>
                  <td><strong>{transactionData ? transactionData.total_amount : 0}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseProductPriceReturnRefundUnderProcessinModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showDeliveryFeeReturnRefundUnderProcessingModal} onHide={handleCloseDeliveryFeeReturnRefundUnderProcessinModal} className="sellerModal" centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Fee  - Return / Refund under processing </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Delivery Fee  - Return / Refund under processing	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Detail</th>
                  <th>Items Name</th>
                  <th>UOM</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {transactionData && transactionData.order_list && transactionData.order_list.length > 0 ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.uom}</td>
                      <td>{order.unit}</td>
                      <td>{order.rate}</td>
                      <td>{order.total}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} style={{ textAlign: 'center' }}>No delivery fee data available.</td>
                  </tr>
                )}

                <tr>
                  <td colSpan={6}><strong>Total Delivery Fee</strong></td>
                  <td><strong>{transactionData ? transactionData.total_amount : ""}</strong></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeliveryFeeReturnRefundUnderProcessinModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showRefundedToCustomerModal} onHide={handleCloseRefundedToCustomerModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Refunded to Customer </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Refunded to Customer	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Unit</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>

                </tr>
              </thead>
              <tbody>
                {/* Render order data if transactionData and order_list are defined */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name || "N/A"}</td>
                      <td>{order.uom || "N/A"}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Total Return Approved Row */}
                {transactionData && (
                  <tr>
                    <td colSpan={9}>
                      <strong>Total of Return Approved</strong>
                    </td>
                    <td>
                      <strong>{transactionData.total_amount.toFixed(2)}</strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRefundedToCustomerModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showCommissionRefundedOnReturnModal} onHide={handleCloseCommissionRefundedOnReturnModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Commission refunded on return </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Commission refunded on return	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Unit</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {/* Render order data if transactionData and order_list are defined */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name || "N/A"}</td>
                      <td>{order.uom || "N/A"}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Subtotal Row for Platform Commission */}
                <tr>
                  <td colSpan={9}>
                    <strong>Sub Total of Platform Commission</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.total_amount : "N/A"}</strong>
                  </td>
                </tr>

                {/* VAT Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>VAT: {transactionData ? transactionData.vat_rate : "0"}%</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.vat_amount : "N/A"}</strong>
                  </td>
                </tr>

                {/* Total Platform Commission Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>Total Platform Commission</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.total_commission_amount : "N/A"}</strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCommissionRefundedOnReturnModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showTransactionFeeRefundedOnReturnModal} onHide={handleCloseTransactionFeeRefundedOnReturnModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Transaction fee refunded on return </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Transaction fee refunded on return	:</p>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Order Details</th>
                  <th>Item Name</th>
                  <th>Unit</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {/* Render order data if transactionData and order_list are defined */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name || "N/A"}</td>
                      <td>{order.uom || "N/A"}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Transaction Fee Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>Transaction Fee</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.total_amount : "N/A"}</strong>
                  </td>
                </tr>

                {/* VAT Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>VAT: {transactionData ? transactionData.vat_rate : "0"}%</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.vat_amount : "N/A"}</strong>
                  </td>
                </tr>

                {/* Total Transaction Fee Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>Total Transaction Fee</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.total_commission_amount : "N/A"}</strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTransactionFeeRefundedOnReturnModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showOtherFeeOneRefundedOnReturnModal} onHide={handleCloseOtherFeeOneRefundedOnReturnModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Other Fee 1 refunded on return </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Other Fee 1 refunded on return	:</p>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Units</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {/* Render order data if transactionData and order_list are defined */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name || "N/A"}</td>
                      <td>{order.uom || "N/A"}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Transaction Fee Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>Transaction Fee</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.total_amount : "N/A"}</strong>
                  </td>
                </tr>

                {/* VAT Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>VAT {transactionData ? transactionData.vat_rate : "0"}%</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.vat_amount : "N/A"}</strong>
                  </td>
                </tr>

                {/* Total Transaction Fee Row */}
                <tr>
                  <td colSpan={9}>
                    <strong>Total Transaction Fee</strong>
                  </td>
                  <td>
                    <strong>{transactionData ? transactionData.total_commission_amount : "N/A"}</strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOtherFeeOneRefundedOnReturnModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showOtherFeeTwoRefundedOnReturnModal} onHide={handleCloseOtherFeeTwoRefundedOnReturnModal} className="sellerModal" centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Other Fee 2 refunded on return </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the details of the Other Fee 2 refunded on return	:</p>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Order Details</th>
                  <th>Items Name</th>
                  <th>Units</th>
                  <th>Product Price Total</th>
                  <th>Status</th>
                  <th>Commission Name</th>
                  <th>UOM</th>
                  <th>Rate</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {/* Check if transactionData and order_list are defined */}
                {transactionData && transactionData.order_list ? (
                  transactionData.order_list.map((order, index) => (
                    <tr key={index}>
                      <td>{order.order}</td>
                      <td>{order.order_details}</td>
                      <td>{order.item_name}</td>
                      <td>{order.unit}</td>
                      <td>{order.total}</td>
                      <td>{order.status}</td>
                      <td>{order.commission_name || "N/A"}</td>
                      <td>{order.uom || "N/A"}</td>
                      <td>{order.rate}%</td>
                      <td>{order.percentage_amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>No transaction data available</td>
                  </tr>
                )}

                {/* Transaction Fee Row */}
                <tr>
                  <td colSpan={9}><strong>Transaction Fee</strong></td>
                  <td><strong>{transactionData ? transactionData.total_amount : "N/A"}</strong></td>
                </tr>

                {/* VAT Row */}
                <tr>
                  <td colSpan={9}><strong>VAT {transactionData ? transactionData.vat_rate : "0"}%</strong></td>
                  <td><strong>{transactionData ? transactionData.vat_amount : "N/A"}</strong></td>
                </tr>

                {/* Total Transaction Fee Row */}
                <tr>
                  <td colSpan={9}><strong>Total Transaction Fee</strong></td>
                  <td><strong>{transactionData ? transactionData.total_commission_amount : "N/A"}</strong></td>
                </tr>
              </tbody>
            </Table></div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOtherFeeTwoRefundedOnReturnModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      {transactionDataLoader && <FullScreenLoader />}

    </div>
  );
};

export default Account;
