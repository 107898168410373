import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image, Spinner } from 'react-bootstrap';
import styles from './TermsAndConditions.style';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/terms.png';
import Section from '../../Components/Section/Section';

import career from "../../assets/images/career.png";
import Dot from "../../assets/images/dot.png";
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';
import { useTranslation } from 'react-i18next';
const TermsAndConditions: React.FC = () => {
    const { t, i18n } = useTranslation();
  
  const breadcrumbs: string[] = [t("footer.home"), t("footer.terms")];
  const title: string = 'Terms and Conditions';
  const info: string = "";
  const imageUrl: string = TitleImage; // Corrected imageUrl



  const navigate = useNavigate()

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);





  return (
    <div className="terms-conditions">

      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="termsConditions common-page" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
          <Row>
            <Col>
              {/* <Heading text="Terms and Conditions" alignment="left" /> */}
              <p style={styles.text}><strong>Effective Date:</strong> [Insert Date]</p>

              <h4>1. Introduction</h4>
              <p style={styles.text}>
                Welcome to Sajilo Life Pvt. Ltd. (“Company”, “we”, “our”, “us”). By accessing or using our website and mobile applications (collectively, the “Platform”), you agree to be bound by these Terms and Conditions (“Agreement”). If you do not agree with these terms, please do not use our Platform.
              </p>

              <h4>2. Account Registration</h4>
              <ul style={styles.text}>
                <li><strong>Eligibility:</strong> You must be legally capable of entering into a binding contract.</li>
                <li><strong>Account Information:</strong> You must provide accurate and complete information during registration and keep your account information up-to-date.</li>
                <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
              </ul>

              <h4>3. Use of the Platform</h4>
              <ul style={styles.text}>
                <li><strong>Permitted Use:</strong> You may use the Platform for lawful purposes only. You agree not to use the Platform in any way that violates any applicable local, national, or international law or regulation.</li>
                <li><strong>Prohibited Activities:</strong> You agree not to engage in any activity that could harm the Platform or its users, including but not limited to hacking, phishing, or distributing malware.</li>
                <li><strong>User Conduct:</strong> You agree to use the Platform in a manner that is ethical and respectful to other users. Any form of harassment, abuse, or inappropriate behavior will not be tolerated.</li>
                <li><strong>Content:</strong> You are responsible for any content you upload, share, or transmit through the Platform. This includes reviews, images, photos, and any other materials. You must ensure that your content does not violate any laws or infringe on the rights of others.</li>
              </ul>

              <h4>4. Orders and Payments</h4>
              <ul style={styles.text}>
                <li><strong>Order Placement:</strong> By placing an order, you agree to purchase the products and services listed in your order.</li>
                <li><strong>Payment:</strong> Payments must be made through the Platform’s payment gateway. You agree to pay all applicable charges, including taxes and shipping fees.</li>
                <li><strong>Order Confirmation:</strong> We will send you an order confirmation email once your order is placed. This email does not constitute acceptance of your order.</li>
              </ul>

              <h4>5. Shipping and Delivery</h4>
              <ul style={styles.text}>
                <li><strong>Shipping:</strong> The seller is responsible for shipping the products to you. Shipping times may vary based on the seller’s location and shipping method.</li>
                <li><strong>Delivery:</strong> The Platform is not responsible for any delays in delivery caused by the seller or shipping carrier.</li>
              </ul>

              <h4>6. Returns and Refunds</h4>
              <ul style={styles.text}>
                <li><strong>Return Policy:</strong> You may return products in accordance with the seller’s return policy and applicable laws.</li>
                <li><strong>Refunds:</strong> Refunds will be processed by the seller in accordance with their return policy and applicable laws.</li>
              </ul>

              <h4>7. Seller Terms and Conditions</h4>
              <ul style={styles.text}>
                <li><strong>Seller Registration:</strong> Sellers must provide accurate and complete information during registration and keep their account information up-to-date.</li>
                <li><strong>Product Listings:</strong> Sellers must ensure that all product listings are accurate, complete, and not misleading. Products must comply with all applicable laws and regulations.</li>
                <li><strong>Order Fulfillment:</strong> Sellers must fulfill orders promptly and within the specified time frame. Sellers are responsible for shipping products to buyers and providing tracking information.</li>
                <li><strong>Payments:</strong> Payments will be processed through the Platform’s payment gateway. Sellers will receive payments after deducting applicable fees.</li>
                <li><strong>Returns and Refunds:</strong> Sellers must have a clear return and refund policy that complies with applicable laws. Sellers are responsible for processing refunds in accordance with their return policy and applicable laws.</li>
                <li><strong>Seller Conduct:</strong> Sellers must conduct their business in a manner that is ethical and respectful to buyers and other sellers. Any form of harassment, abuse, or inappropriate behavior will not be tolerated.</li>
                <li><strong> Lawful Business Activities:</strong> Sellers are permitted to conduct only lawful business activities on the Platform. If any unlawful activities are found, the Platform and its users can report them to the respective authorities for required action.</li>
                <li><strong>Responsibility for Taxes: </strong>Sellers are responsible for paying all applicable taxes to the government, including income tax, local taxes, and customs taxes on the products they sell.</li>
                <li><strong>Product Accuracy and Expiry:</strong> Sellers are responsible for delivering unexpired and accurate products. They must ensure that all products meet the described specifications and quality standards.</li>
                <li><strong>Business Permissions: </strong>Sellers are responsible for obtaining all necessary business permissions and licenses from the relevant authorities.</li>
                <li><strong>Invoicing: </strong>Sellers must issue proper invoices for every product they sell through the Platform.</li>
                <li><strong>Responsibility for Illegal Activities:</strong> Sellers are solely responsible for ensuring that their products and activities comply with all applicable laws. The Platform is not responsible for any illegal activities conducted by sellers. Sajilo Life does not bear any responsibility whatsoever for any consequences caused due to infringement of laws by sellers.</li>
                <li><strong>After-Sales and Warranty:</strong> Any after-sales service or warranty obligations shall be borne by the seller and the respective related parties. The Platform is not responsible for any after-sales service or warranty.</li>
              </ul>

              <h4>8. Delivery Partner Terms and Conditions</h4>
              <ul style={styles.text}>
                <li><strong>Delivery Partner Registration:</strong> Delivery partners must provide accurate and complete information during registration and keep their account information up-to-date.</li>
                <li><strong>Delivery Services:</strong> Delivery partners agree to provide delivery services in a timely and professional manner, ensuring that all deliveries are completed within the specified time frame.</li>
                <li><strong>Payments: </strong> Delivery partners will receive payments after deducting applicable fees.</li>
                <li><strong>Delivery Responsibilities:</strong> Delivery partners must ensure that all deliveries are made promptly and within the specified time frame. Delivery partners are responsible for the safe handling and transportation of goods.</li>
                <li><strong>Delivery Partner Conduct:</strong> Delivery partners must conduct their services in a manner that is ethical and respectful to sellers, buyers, and other delivery partners. Any form of harassment, abuse, or inappropriate behavior will not be tolerated.</li>
                <li><strong>Responsibility for Illegal Activities: </strong>Delivery partners are solely responsible for ensuring that their activities comply with all applicable laws. The Platform is not responsible for any illegal activities conducted by delivery partners.</li>
              </ul>

              <h4>9. Data Protection and Respectful Communication</h4>
              <ul style={styles.text}>
                <li><strong>Data Protection:</strong> No party shall sell or misuse any data of either party or harm any party in any way. All parties must handle personal data in accordance with applicable data protection laws.</li>
                <li><strong>Respectful Communication:</strong> A respectful communication and relationship will be maintained during and after the business transactions. Any form of harassment, abuse, or inappropriate behavior will not be tolerated. </li>
              </ul>

              <h4>10. Intellectual Property</h4>
              <ul style={styles.text}>
                <li><strong>Ownership:</strong> : All content on the Platform, including text, graphics, logos, and images, is the property of the Platform or its licensors and is protected by intellectual property laws.      </li>
                <li><strong>Use:</strong> You may not use any content from the Platform without the express written permission of the Platform.</li>
              </ul>

              <h4>11. Compliance with Laws</h4>
              <ul style={styles.text}>
                <li><strong>Legal Compliance:</strong> : You must comply with all applicable laws, including consumer protection laws and data protection laws.      </li>
                <li><strong>Data Protection:</strong> Your personal data will be handled in accordance with the Platform’s Privacy Policy and applicable data protection laws, including the Data Act 2079 (2022) and the Individual Privacy Act 2075 (2018).      </li>
              </ul>

              <h4>12. Limitation of Liability</h4>
              <ul style={styles.text}>
                <li><strong>Liability:</strong> The Platform is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Platform.</li>
                <li><strong>Marketplace Role:</strong> The Platform acts solely as a facilitator for transactions between buyers and sellers. The Platform is not responsible for any illegal activities conducted by buyers or sellers. Any consequences arising from illegal activities must be borne by the respective parties involved.</li>
              </ul>
              <h4>13. Indemnification</h4>
              <ul style={styles.text}>
                <li>Sellers, buyers, and delivery partners agree to indemnify any losses caused to Sajilo Life from any claims, damages, or losses arising from their actions or omissions. This includes any legal fees and costs incurred by Sajilo Life in connection with such claims  </li>
              </ul>

              <h4>14. Force Majeure</h4>
              <ul style={styles.text}>
                <li>For the purposes of this agreement, “Force Majeure” refers to any event or circumstance beyond the reasonable control of a party. This includes, but is not limited to, natural disasters (such as earthquakes, floods, or hurricanes), war, terrorism, civil unrest, strikes, lockouts, labor disputes, government actions, pandemics, or any other event that could not have been reasonably anticipated or avoided.</li>
                <li>If a party is prevented, hindered, or delayed from performing any of its obligations under this agreement due to a Force Majeure event, such party shall be excused from the performance of those obligations to the extent and for the duration that such performance is prevented, hindered, or delayed by the Force Majeure event.</li>
                <li>The affected party shall promptly notify the other party in through appropriate communication channel of the occurrence of the Force Majeure event, providing details of the nature, extent, and expected duration of the event, as well as the obligations affected.</li>
                <li>The affected party shall use all reasonable efforts to mitigate the effects of the Force Majeure event and to resume the performance of its obligations as soon as reasonably practicable.</li>
                <li>If the Force Majeure event continues for a period exceeding 30 days, either party may terminate this agreement by providing written notice to the other party. In such an event, neither party shall have any liability to the other except for obligations that accrued prior to the Force Majeure event.</li>
                <li>Force Majeure shall not include financial distress, changes in market conditions, or the inability to pay any amounts due under this agreement.</li>
                <li>The occurrence of a Force Majeure event shall not extend the term of this agreement unless mutually agreed upon in writing by both parties.</li>
              </ul>

              <h4>15. Governing Law</h4>
              <ul style={styles.text}>
                <li><strong>Jurisdiction:</strong> This Agreement is governed by the laws of Nepal, and any disputes will be resolved in the courts of Kathmandu.</li>
              </ul>

              <h4>16. Changes to This Agreement</h4>
              <p style={styles.text}>
                We may update our Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms and Conditions on this page. You are advised to review these Terms and Conditions periodically for any changes. Changes to these Terms and Conditions are effective when they are posted on this page.
              </p>

              <h4>17. Contact Information</h4>
              <p style={styles.text}>
                If you have any questions or comments about these Terms and Conditions, please contact us at:
                <strong>Sajilo Life Pvt. Ltd.</strong>
                Pepsicola, Kathmandu, Nepal<br />
                <strong>Email:</strong> <a href="mailto:customer.support@sajilolife.com">customer.support@sajilolife.com</a> <br />
                <strong>Phone:</strong> <a href="tel:+9779708073716">+977-9708073716</a>
              </p>

            </Col>
          </Row>


        </Container>
      </Section>
    </div>
  );
};

export default TermsAndConditions;
