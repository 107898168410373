import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { getStyles } from "./AddProduct.style";
import AddProductImage from "../../assets/images/addProductTitle.png";
import TitleBar from "../../Components/TitleBar/TitleBar";
import Section from "../../Components/Section/Section";
import { toast } from "react-toastify";
import {
  Add_Bulk_Upload,
  Add_Product,
  All_Subcategories,
  subcategories,
  Unit_Of_Measure,
} from "../../Api";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../Api/ApiConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FaArrowLeft, FaSearch } from "react-icons/fa"; // Import FaArrowLeft icon

import AddProductFromSystem from "../../Components/AddProductFromSystem/AddProductFromSystem";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Category {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
  // store_name: string;
  // email: string;
  // contact_number: any
}

const AddProduct: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const styles = getStyles(isMobile);
  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    "Store",
  ];
  const title: string = t("product.addProduct");

  const imageUrl: string = AddProductImage;

  const [productType, setProductType] = useState<"single" | "bulk" | "system">(
    "single"
  );
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<any>("");
  const [variants, setVariants] = useState("");
  const [minQuantity, setMinQuantity] = useState("");
  const [inventory, setInventory] = useState("");
  const [price, setPrice] = useState("");
  const [productImage, setProductImage] = useState<any>("");
  const [fileType, setFileType] = useState<any>("");
  const [additionalImage1, setAdditionalImage1] = useState<any>("");
  const [additionalImage2, setAdditionalImage2] = useState<any>("");

  const [userToken, setUserToken] = useState<string | null>("");
  const [sub, setSub] = useState<Category[]>([]);
  const navigate = useNavigate();

  const [productQuantity, setProductQuantity] = useState("");
  const [systemProductID, setSystemProductID] = useState("");
  const [productNameInNepali, setProductNameInNepali] = useState("");
  const [descriptionInNepali, setDescriptionInNepali] = useState("");
  const [priceInNepali, setPriceInNepali] = useState("");
  const [template, setTemplate] = useState<any>({});
  const [storeId, setStoreId] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [file, setFile] = useState<any>(null);
  const [zip, setZip] = useState<any>(null);
  const [productError, setProductError] = useState<any>("");
  const [fileName, setFileName] = useState<any>("");
  const [zipFileName, setZipFileName] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [bulkErrorDetails, setBulkErrorDetails] = useState<any>(null); // New state to store error message and file path

  const [storeError, setStoreError] = useState<{
    PRDWeight?: string;
    available?: string;
    product_image?: string;
    maximum_qty?: string;
    // store_type?:string;
  }>({});
  // const [storeError, setStoreError] = useState<{
  //   product_image?: string;
  // }>({});

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await subcategories();
      if (response && response.status === 200) {
        setSub(response.data.sub_cat_list);
      } else {
        console.log("Error fetching Subcategories");
      }
    } catch (error) {
      console.log("Error fetching Subcategories", error);
    }
  };

  const fetchUnit = async () => {
    try {
      const response = await Unit_Of_Measure();
      if (response && response.status === 200) {
        console.log(response.data, "response==============>");
        // setSub(response.data);
        setUnitsOfMeasure(response.data.product_list);
      } else {
        console.log("Error fetching Subcategories");
      }
    } catch (error) {
      console.log("Error fetching Subcategories", error);
    }
  };

  // const fetchBulk = async () => {
  //   try {
  //     const response = await Get_Bulk_Upload(userToken);
  //     if (response && response.status === 200) {
  //       setStoreId(response.data.stores_list[0].id)
  //     } else {
  //       console.log("Error fetching BulkFile");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching BulkFile", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchCategories();
  // }, []);

  const schema = yup.object().shape({
    productName: yup
      .string()
      .required(t("responseMessage.productNameRequired")),
    unitOfMeasure: yup.string().required(t("responseMessage.selectAnOption")),
    category: yup.string().required(t("responseMessage.selectAnOption")),
    maxQuantity: yup.string(),
    // .matches(/^[0-9]+$/, "* Invalid variants"),
    productNameInNepali: yup
      .string()
      .required(t("responseMessage.neNameRequired")),
    descriptionInNepali: yup
      .string()
      .required(t("responseMessage.neDescriptionRequired")),
    variants: yup
      .string()
      .required(t("responseMessage.variantsRequired"))
      .matches(/^[0-9]+(\.[0-9]+)?$/, "Invalid variants"),

    priceInNepali: yup
      .string()
      .required(t("responseMessage.priceRequired"))
      .matches(/^[0-9]+(\.[0-9]+)?$/, t("responseMessage.invalidPrice")),

    description: yup
      .string()
      .required(t("responseMessage.descriptionRequired"))
      .matches(
        /^[a-zA-Z0-9\s!@#$%^&*()\-]+$/,
        t("responseMessage.addValidDescription")
      ),
    threshold_limit: yup
      .string()
      .required("* Threshold limit is required")
      .matches(/^[0-9]+(\.[0-9]+)?$/, "* Invalid limit"),

    minQuantity: yup
      .string()
      .default('1')
      .required(t("responseMessage.qtyRequired"))
      .matches(/^[0-9]+(\.[0-9]+)?$/, t("responseMessage.invalidQuantity"))
      .test("minQuantity", t("responseMessage.minQuantityGreaterThanZero"), (value) => {
        return parseFloat(value) > 0;
      }),

    // price: yup
    //   .string()
    //   .required(t("responseMessage.priceRequired"))
    //   .matches(/^[0-9]+(\.[0-9]+)?$/, t("responseMessage.invalidPrice")),

    inventory: yup
      .string()
      .required(t("responseMessage.inventoryRequired"))
      .matches(/^[0-9]+$/, t("responseMessage.invalidInventory")),

    sku: yup.string().required("* Sku is required"),

    brand: yup.string().required("* Brand name is required"),
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    // defaultValues: {
    //   inventory: "0", // Default value for inventory
    // },
    resolver: yupResolver(schema),
    // defaultValues: {
    //   productName: 'Default Product Name', // Set your default value here
    // }
  });

  const handleProductTypeChange = (type: "single" | "bulk" | "system") => {
    setProductType(type);
    // Reset related fields when switching product types
    setProductName("");
    setCategory("");
    setDescription("");
    setUnitsOfMeasure("");
    setVariants("");
    setMinQuantity("");
    setInventory("");
    setPrice("");
    setProductQuantity("");
    setSystemProductID("");
    setProductNameInNepali("");
    setDescriptionInNepali("");
    setPriceInNepali("");
  };

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
    setStatus(localStorage.getItem(STORAGE_CONSTANTS.STORE_STATUS));
    fetchCategories();
    fetchUnit();
    window.scrollTo(0, 0);
  }, []);

  const handleSubmits = async (data: any) => {
    if (data) {
      try {
        setLoading(true);
        const response: any = await Add_Product(
          data,
          productImage,
          additionalImage1,
          additionalImage2
        );
        if (response && response.status === 200) {
          toast.success(response["data"]["message"]);
          console.log(response.data);
          navigate("/products");
        } else {
          console.log(response.data, "error=======>");
          // toast.error(response?.data?.error_message);
        }
      } catch (error: any) {
        console.error("Error occurred:", error.response.data);
        setStoreError(error?.response?.data?.error_message);
        setProductError(error?.response?.data?.error_message);
      } finally {
        setLoading(() => false);
      }
    }
  };

  const handleFileChange = (e: any) => {
    console.log("product image", e);
    setProductImage(e.target.files[0]);
    e.preventDefault();
  };

  const handleAdditionalFile1 = (e: any) => {
    console.log("product image1", e);
    setAdditionalImage1(e.target.files[0]);
  };

  const handleAdditionalFile2 = (e: any) => {
    console.log("product image1", e);
    setAdditionalImage2(e.target.files[0]);
  };

  console.log(productImage);

  const handleBulkFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (e.target.files && e.target.files.length > 0) {
    //   const selectedFile = e.target.files[0];
    //   setFile(selectedFile);
    // }
    const file = e.target.files?.[0];
    console.log(file, "file========>");
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFile(file);
      getFileExtension(file.name);
    }
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFileName(selectedFile.name);
    }
  };

  const handleBulkZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log(file, "file========>");
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      // setSelectedAdditionalImage1(imageUrl);
      setZip(file);
    }
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setZipFileName(selectedFile.name);
    }
  };

  const getFileExtension = (filename: any) => {
    const parts = filename.split(".");
    const fileType = parts.pop();
    if (fileType === "xls" || fileType === "xlsx") {
      setFileType("excel");
    } else if (fileType === "csv") {
      setFileType("csv");
    }
  };

  // const handleFormSubmit = (e: any) => {
  //   e.preventDefault();
  //   // alert("hiii")
  //   if (file) {
  //     const BulkProduct = async () => {
  //       try {
  //         setLoading(true);
  //         const response = await Add_Bulk_Upload(
  //           userToken,
  //           storeId,
  //           file,
  //           zip,
  //           fileType
  //         );
  //         if (response && response.status === 202) {
  //           showToastMessage(response.data);
  //           console.log(response.data);
  //           navigate("/products");
  //           setFile("");
  //           setZip("");
  //           setFileType("");
  //           setFileName("");
  //           setZipFileName("");
  //         } else {
  //           console.log(response.data);
  //         }
  //       } catch (error: any) {
  //         console.error("Error occurred:", error.response.data);
  //         setFile("");
  //         setZip("");
  //         setFileType("");
  //         setFileName("");
  //         setZipFileName("");
  //         setStoreError(error.response.data);
  //       } finally {
  //         setLoading(() => false)
  //       }
  //     };
  //     BulkProduct();
  //   } else {
  //     toast.error("Please select a file to upload");
  //   }
  //   console.log("Uploading file:", file);
  // };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (file) {
      const BulkProduct = async () => {
        try {
          setLoading(true);
          const response = await Add_Bulk_Upload(
            userToken,
            storeId,
            file,
            zip,
            fileType
          );

          // Check for status code 202 (successful upload)
          if (response.status === 202) {
            showToastMessage(response.data);
            console.log(response.data,"==================");

            // If the bulk upload completed with errors
            if (response.data.errors && response.data.error_file_path) {
              setBulkErrorDetails({
                message: response.data.message,
                errorFilePath: response.data.error_file_path,
                totalFaildProduct: response.data.total_faild_product,
                totalUploadProduct: response.data.total_upload_product,
                createdRecord: response.data.created,
                error:response.data.error
              });
            }

            navigate("/products");
            resetForm();
          }
          // Handle case for 400 status (Bad Request)
          else if (response.status === 400) {
            setBulkErrorDetails({
              message: response.data.message || "Bulk upload completed with errors.",
              error:response.data.error,
              errorFilePath: response.data.error_file_path || null,
              totalFaildProduct: response.data.total_faild_product || null,
                totalUploadProduct: response.data.total_upload_product || null,
                createdRecord: response.data.created || null
            });

          }
          // Handle any other unexpected status codes
          else {
            setBulkErrorDetails({
              message: "Bulk upload failed with an unexpected error.",
              errorFilePath: null,
              totalFaildProduct: null,
              totalUploadProduct: null,
              createdRecord: null,
              error:null
            });
          }
        } catch (error: any) {
          console.error("Error occurred:", error.response?.data || error);
          if (error.response?.data) {
            // Specific error message and details
            setBulkErrorDetails({
              message: error.response?.data.message || "Error occurred during bulk upload.",
              errorFilePath: error.response?.data.error_file_path || null,
              totalFaildProduct: error.response?.data.total_faild_product || null,
              totalUploadProduct: error.response?.data.total_upload_product || null,
              createdRecord: error.response?.data.created || null,
              error:error.response.data.error || null
            });
          } else {
            setBulkErrorDetails({
              message: "An unexpected error occurred during the upload.",
              errorFilePath: null,
              totalFaildProduct: null,
              totalUploadProduct: null,
              createdRecord: null,
              error:null
            });
          }
          resetForm();
        }
        finally {
          setLoading(false);
        }
      };
      BulkProduct();
    } else {
      toast.error("Please select a file to upload");
    }
    console.log("Uploading file:", file);
  };

  // Reset form function
  const resetForm = () => {
    setFile("");
    setZip("");
    setFileType("");
    setFileName("");
    setZipFileName("");
  };

  // Handle file download
  const handleFileDownload = () => {
    if (bulkErrorDetails?.errorFilePath) {
      const link = document.createElement("a");
      link.href = bulkErrorDetails.errorFilePath; // Set the file URL
      link.target = "_blank"; // Open in a new tab if needed (optional)
      link.download = "error_log.csv"; // Set the desired download file name
      link.click(); // Programmatically trigger the click event
    } else {
      console.error("Error file path is not available");
    }
  };


  const showToastMessage = (response: any) => {
    const { created, repeated } = response;

    const repeatedProductText = repeated > 1 ? "Products" : "Product";
    const createdProductText = created > 1 ? "Products" : "Product";

    if (created > 0 && repeated === 0) {
      toast.success("Bulk upload successful");
    } else if (created > 0 && repeated > 0) {
      toast.success(
        `${created} new ${createdProductText} added, ${repeated} ${repeatedProductText} repeated`
      );
    } else if (created === 0 && repeated > 0) {
      toast.error(`${repeated} ${repeatedProductText} repeated`);
    }
  };

  // const downloadSampleCSV = (e:any) => {
  //   // Replace the sample data with your actual CSV template
  //   e.preventDefault()
  //   // const url = template.csv;
  //   // const link = document.createElement("a");
  //   // link.href = url;
  //   // link.setAttribute("download", "sample.csv");
  //   // document.body.appendChild(link);
  //   // link.click();
  //   // document.body.removeChild(link);
  //   fetchBulk()
  // };

  // console.log(file,"file=------------")
  // console.log(storeId, "erewwwwwwwww");

  return (
    <div className="addProductWrap">
      <TitleBar breadcrumbs={breadcrumbs} title={title} imageUrl={imageUrl} />
      <Section className="addProduct" style={{ marginTop: -60 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{ position: "absolute", left: 0, top: 5 }}
                  className="hide-xs"
                >
                  <Link to="/products">
                    <FaArrowLeft className="arrow-left" />{" "}
                  </Link>
                </div>
                <div
                  style={{ position: "relative", left: 0, top: 0 }}
                  className="hide-lg"
                >
                  <Link to="/products">
                    <FaArrowLeft className="arrow-left" />{" "}
                  </Link>
                </div>
                <Form.Group>
                  <Form.Check
                    inline
                    type="radio"
                    id="single"
                    label={t("product.single")}
                    checked={productType === "single"}
                    onChange={() => handleProductTypeChange("single")}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="bulk"
                    label={t("product.bulk")}
                    checked={productType === "bulk"}
                    onChange={() => handleProductTypeChange("bulk")}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="system"
                    label={t("product.fromSystem")}
                    checked={productType === "system"}
                    onChange={() => handleProductTypeChange("system")}
                  />
                </Form.Group>
              </div>

              {productType === "single" && (
                <>
                  <Form.Group controlId="productName">
                    <Form.Label>{t("product.productName")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.enterProductName")}
                      {...register("productName")}
                    />
                    {errors?.productName && (
                      <span style={styles.error}>
                        {errors?.productName?.message}
                      </span>
                    )}
                    {productError && (
                      <span style={styles.error}>{productError}</span>
                    )}

                    {/* {storeError && (<span style={styles.error}>
                        {storeError}
                      </span>)} */}
                  </Form.Group>
                  <Form.Group controlId="category">
                    <Form.Label>{t("product.category")}*</Form.Label>
                    <Controller
                      name="category"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Form.Control as="select" {...field}>
                          <option value="" disabled>
                            {t("product.selectSubcategory")}
                          </option>
                          {sub.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Control>
                      )}
                    />
                    {errors?.category && (
                      <span style={styles.error}>
                        {errors?.category?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="description">
                    <Form.Label>{t("product.description")}*</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder={t("product.enterProductDescription")}
                      {...register("description")}
                    // value={description}
                    // onChange={(e) => setDescription(e.target.value)}
                    />
                    {errors?.description && (
                      <span style={styles.error}>
                        {errors?.description?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} controlId="unitOfMeasure">
                      <Form.Label>{t("product.unitOfMeasure")}*</Form.Label>
                      <Controller
                        name="unitOfMeasure"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control as="select" {...field}>
                            <option value="" disabled>
                              {"Select an option"}
                            </option>
                            {unitsOfMeasure &&
                              unitsOfMeasure.map((item: any) => (
                                <option
                                  key={item.id}
                                  value={item.unit_of_measure}
                                >
                                  {item.unit_of_measure}
                                </option>
                              ))}
                          </Form.Control>
                        )}
                      />
                      {errors?.unitOfMeasure && (
                        <span style={styles.error}>
                          {errors?.unitOfMeasure?.message}
                        </span>
                      )}
                    </Form.Group>

                    {/* <Form.Group as={Col} controlId="price">
                      <Form.Label>{t("product.priceUsd")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.enterProductPrice")}
                        {...register("price")}

                        // value={price}
                        // onChange={(e) => setPrice(e.target.value)}
                      />
                      {errors?.price && (
                        <span style={styles.error}>
                          {errors?.price?.message}
                        </span>
                      )}
                    </Form.Group> */}
                    <Form.Group as={Col} controlId="variants">
                      <Form.Label>{t("product.variations")}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.enterProductVariants")}
                        {...register("variants")}

                      // value={inventory}
                      // onChange={(e) => setInventory(e.target.value)}
                      />
                      {errors?.variants && (
                        <span style={styles.error}>
                          {errors?.variants?.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="minQuantity">
                      <Form.Label>{t("product.minimumQuantity")}*</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue="1"
                        placeholder={t("product.enterMinimumQuantity")}
                        {...register("minQuantity")}

                      // value={minQuantity}
                      // onChange={(e) => setMinQuantity(e.target.value)}
                      // required
                      />
                      {errors?.minQuantity && (
                        <span style={styles.error}>
                          {errors?.minQuantity?.message}
                        </span>
                      )}
                      {storeError?.PRDWeight && (
                        <span style={styles.error}>
                          {storeError?.PRDWeight}
                        </span>
                      )}
                    </Form.Group>

                    {/* 
                    <Form.Group as={Col} controlId="unitOfMeasure">
                      <Form.Label>{t("product.unitOfMeasure")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.unitOfMeasure")}
                        {...register("unitOfMeasure")}
                        // value={unitOfMeasure}
                        // onChange={(e) => setUnitOfMeasure(e.target.value)}
                      />
                      {errors.unitOfMeasure && (
                        <span style={styles.error}>
                          {errors.unitOfMeasure.message}
                        </span>
                      )}
                    </Form.Group> */}

                    <Form.Group as={Col} controlId="maxQuantity">
                      <Form.Label>{t("product.maximumQuantity")}*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={t("product.enterMaximumQuantity")}
                        {...register("maxQuantity")}

                      // value={variants}
                      // onChange={(e) => setVariants(e.target.value)}
                      />
                      {/* {errors?.maxQuantity && (
                        <span style={styles.error}>
                          {errors?.maxQuantity?.message}
                        </span>
                      )} */}
                      {storeError?.maximum_qty && (
                        <span style={styles.error}>
                          {storeError?.maximum_qty}
                        </span>
                      )}
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="Brand">
                      <Form.Label>{t("product.brand")}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.enterBrand")}
                        {...register("brand")}

                      // value={minQuantity}
                      // onChange={(e) => setMinQuantity(e.target.value)}
                      // required
                      />
                      {errors?.brand && (
                        <span style={styles.error}>
                          {errors?.brand?.message}
                        </span>
                      )}
                      {/* {storeError?.PRDWeight && (
                        <span style={styles.error}>
                          {storeError?.PRDWeight}
                        </span>
                      )} */}
                    </Form.Group>

                    <Form.Group as={Col} controlId="Sku">
                      <Form.Label>{t("product.sku")}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("product.enterSku")}
                        {...register("sku")}

                      // value={variants}
                      // onChange={(e) => setVariants(e.target.value)}
                      />
                      {errors?.sku && (
                        <span style={styles.error}>{errors?.sku?.message}</span>
                      )}
                      {/* {storeError?.maximum_qty && (
                        <span style={styles.error}>
                          {storeError?.maximum_qty}
                        </span>
                      )} */}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} controlId="inventory">
                      <Form.Label>{t("product.inventory")}*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={t("product.inventory")}
                        {...register("inventory")}

                      // value={inventory}
                      // onChange={(e) => setInventory(e.target.value)}
                      />
                      {errors?.inventory && (
                        <span style={styles.error}>
                          {errors?.inventory?.message}
                        </span>
                      )}
                      {storeError?.available && (
                        <span style={styles.error}>{storeError?.available}</span>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="threshold_limit">
                      <Form.Label>{"Threshold limit"}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Enter threshold limit"}
                        {...register("threshold_limit")}

                      // value={priceInNepali}
                      // onChange={(e) => setPriceInNepali(e.target.value)}
                      // required
                      />
                      {errors?.priceInNepali && (
                        <span style={styles.error}>
                          {errors?.threshold_limit?.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                  <Form.Group as={Col} controlId="priceInNepali">
                    <Form.Label>{t("product.priceInNepali")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.priceInNepali")}
                      {...register("priceInNepali")}

                    // value={priceInNepali}
                    // onChange={(e) => setPriceInNepali(e.target.value)}
                    // required
                    />
                    {errors?.priceInNepali && (
                      <span style={styles.error}>
                        {errors?.priceInNepali?.message}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group controlId="productImage">
                    <Form.Label>{t("product.productImage")}</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e)}
                    />
                    {storeError?.product_image && (
                      <span style={styles.error}>
                        {storeError?.product_image}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="AdditionalImage1">
                    <Form.Label>{t("product.additionalImage1")}</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleAdditionalFile1(e)}
                    />
                    {/* {storeError.Additional_image && (
                      <span style={styles.error}>
                        {storeError.Additional_image}
                      </span>
                    )} */}
                  </Form.Group>
                  <Form.Group controlId="AdditionalImage2">
                    <Form.Label>{t("product.additionalImage2")}</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleAdditionalFile2(e)}
                    />
                    {/* {storeError.Additional_image && (
                      <span style={styles.error}>
                        {storeError.Additional_image}
                      </span>
                    )} */}
                  </Form.Group>

                  <p style={styles.heading}>{t("product.otherLanguage")}</p>
                  <Form.Group as={Col} controlId="productNameInNepali">
                    <Form.Label>{t("product.productNameInNepali")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.productNameInNepali")}
                      {...register("productNameInNepali")}

                    // value={productNameInNepali}
                    // onChange={(e) => setProductNameInNepali(e.target.value)}
                    // required
                    />
                    {errors?.productNameInNepali && (
                      <span style={styles.error}>
                        {errors?.productNameInNepali?.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group as={Col} controlId="descriptionInNepali">
                    <Form.Label>{t("product.descriptionInNepali")}*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.descriptionInNepali")}
                      {...register("descriptionInNepali")}

                    // value={descriptionInNepali}
                    // onChange={(e) => setDescriptionInNepali(e.target.value)}
                    // required
                    />
                    {errors?.descriptionInNepali && (
                      <span style={styles.error}>
                        {errors?.descriptionInNepali?.message}
                      </span>
                    )}
                  </Form.Group>

                  <div className="align-items-center justify-content-center d-flex">
                    <button
                      type="submit"
                      className="btnBlue submit"
                      disabled={isLoading}
                      onClick={handleSubmit(handleSubmits)}
                    >
                      {isLoading
                        ? <BtnLoader />
                        : t("commonFormLabels.submit")
                      }
                    </button>
                  </div>
                </>
              )}

              {productType === "bulk" && (
                <>
                  <Row
                    className="text-center mt-5"
                    style={{ maxWidth: 600, margin: "auto" }}
                  >
                    <Col>
                      <form>
                        <div>
                          <label
                            htmlFor="csvFile"
                            className="file-upload-label"
                            style={{ ...styles.uploadTxt, cursor: "pointer" }}
                          >
                            <FontAwesomeIcon icon={faFile as any} className="mx-1" />{" "}
                            {t("product.upload")}
                            <input
                              type="file"
                              id="csvFile"
                              accept=".csv, .xls, .xlsx"
                              onChange={handleBulkFileChange}
                              style={{ display: "none" }}
                            />
                          </label>

                          <p style={styles.uploadedFile}>{fileName}</p>
                        </div>
                        <div>
                          <label
                            // htmlFor="csvFile"
                            className="file-upload-label"
                            style={{ ...styles.uploadTxt, cursor: "pointer" }}
                          >
                            <FontAwesomeIcon icon={faFile as any} className="mx-1" />{" "}
                            {"zip"}
                            <input
                              type="file"
                              id="csvFile1"
                              accept=".zip"
                              onChange={handleBulkZipChange}
                              style={{ display: "none" }}
                            />
                          </label>
                          <p style={styles.uploadedFile}>{zipFileName}</p>
                        </div>
                        {bulkErrorDetails?.message && (
  <div style={{ ...styles.uploadedFile, color: "red" }}>
    <p>
      <b>{bulkErrorDetails.message}</b>
      <br />
      Total Records: {bulkErrorDetails.totalUploadProduct || 0}, 
      Success: {bulkErrorDetails.createdRecord || 0}, 
      Failure: {bulkErrorDetails.totalFaildProduct || 0}
      <br />
      {bulkErrorDetails?.error && (
        <span style={{ color: "red" }}>
          Error: {bulkErrorDetails.error}
        </span>
      )}
      <br />
      {bulkErrorDetails?.errorFilePath && (
        <a
          href={bulkErrorDetails.errorFilePath}
          download="error_log.csv"
        >
          Click <span style={{ textDecoration: "underline" }}>here</span> to download file with error records
        </a>
      )}
    </p>
  </div>
)}

                        <h3 style={styles.labelText}>
                          {t("product.bulkInfo")}
                        </h3>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center ">
                            <FontAwesomeIcon
                              icon={faFile as any}
                              style={styles.templateIcon}
                            />
                            <h3 style={styles.templateText} className="mb-0">
                              Template 1
                            </h3>
                          </div>
                          <a
                            href={`${base_url}api/v0/store/product/bulk_upload/sample`}
                          >
                            {t("product.download")}
                          </a>
                        </div>
                        <button
                          onClick={handleFormSubmit}
                          disabled={isLoading}
                          className="btnBlue submit"
                        >
                          {isLoading
                            ? <BtnLoader />
                            : t("commonFormLabels.submit")
                          }
                        </button>
                      </form>
                    </Col>
                  </Row>
                </>
              )}

              {productType === "system" && (
                // <Form.Group controlId="systemProductID">
                //   <Form.Label>System Product ID</Form.Label>
                //   <Form.Control
                //     type="text"
                //     placeholder="Enter system product ID"
                //     value={systemProductID}
                //     onChange={(e) => setSystemProductID(e.target.value)}
                //     required
                //   />
                // </Form.Group>
                <AddProductFromSystem />
              )}
            </Form>
          </Container>
        </div>
      </Section>
    </div>
  );
};

export default AddProduct;

const BtnLoader = () => {
  return <Spinner
    as="span"
    animation="grow"
    size="sm"
    role="status"
    aria-hidden="true"
  />
}
