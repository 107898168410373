import React, { useState, useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { reverseGeocode } from "../../Utils/LocationFinder";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Image,
  Form,
  FormControl,
  Button,
  Modal,
} from "react-bootstrap";
import styles from "./LocationComponent.style";
import {
  SET_SELECTED_LATITUDE,
  SET_SELECTED_LOCALITY,
  SET_SELECTED_LONGITUDE,
  SET_SELECTED_PINCODE,
} from "../../Redux/Location";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import arrowDown from "../../assets/images/arrowDown.png";
import GPS from "../../assets/images/gps.png";
import { RootState } from "../../Redux/Store/Store";
import "./LocationAutoComplete.css";

const libraries: any = ["places"];
const googleMapsApiKey: any = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const LocationComponent: React.FC = () => {
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.Autocomplete | any
  >(null);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [showLocationModal, setShowLocationModal] = useState<any>(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const userLocation = useSelector(
    (state: RootState) => state.Location.locality_name
  );
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       setCurrentLocation({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //           handleStartReverseGeocoding(details?.geometry?.location?.lat, details?.geometry?.location?.lng, googleMapsApiKey);

  //     });
  //   } else {
  //     alert('Geolocation is not supported by this browser.');
  //   }
  // }, []);

  // const geo = navigator.geolocation;

  // // Get user current location
  // geo.getCurrentPosition(userCoords);

  useEffect(() => {
    if (userLatitude === "" || userLongitude === "") {
      // setShowLocationModal(true);
      handleLocation();
    }
  }, []);
  useEffect(() => {
    console.log("Redux State Updated:", {
      userLocation,
      userPincode,
    });
  }, [userLocation, userPincode]);
  function userCoords(position: any) {
    const userLatitude = position.coords.latitude;
    const userLongitude = position.coords.longitude;
    // dispatch(SET_SELECTED_LATITUDE(userLatitude))
    // dispatch(SET_SELECTED_LONGITUDE(userLongitude))
    handleStartReverseGeocoding(userLatitude, userLongitude, googleMapsApiKey);
    // localStorage.setItem(STORAGE_CONSTANTS.LATITUDE,userLatitude)
    // localStorage.setItem(STORAGE_CONSTANTS.LONGITUDE,userLongitude)
    // setLatitude(userLatitude);
    // setLongitude(userLongitude);
  }

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          userCoords(position);
          setShowLocationModal(false);
        },
        (error) => {
          console.error("Error getting location:", error);
          setShowLocationModal(false);
          setSearchQuery("")
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setShowLocationModal(false);
    }
  };

  const handlePlaceChanged = async () => {
    const details = await autocomplete.getPlace();
    setSearchQuery(details.formatted_address);

    try {
      const { lat, lng } = details.geometry.location;
      const latitude = typeof lat === "function" ? lat() : null;
      const longitude = typeof lng === "function" ? lng() : null;

      if (details?.geometry && details?.geometry?.location) {
        handleStartReverseGeocoding(latitude, longitude, googleMapsApiKey);
      }
    } catch (error) {
      console.log("error in selectLocation--->", error);
    }
  };

  const handleStartReverseGeocoding = async (
    latitude: any,
    longitude: any,
    apiKey: any
  ) => {
  

    try {
      const location: any = await reverseGeocode(latitude, longitude, apiKey);
      console.log("Reverse Geocoding Response:", location);
      if (Object.keys(location)?.length > 0) {
        dispatch(SET_SELECTED_LATITUDE(location.lat));
        dispatch(SET_SELECTED_LONGITUDE(location.long));
        dispatch(SET_SELECTED_LOCALITY(location.locationName));
        dispatch(SET_SELECTED_PINCODE(location.pincode));
        setShowLocationModal(false);
      }
      // setLoader(() => false);
      // navigation.goBack();
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
      // setLoader(() => false);
      throw error;
    }
  };

  const handleLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value);
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: event.target.value },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          // console.log("predictions--->", predictions);
        }
      }
    );
  };

  // console.log(userLocation,"loact=======>")

  return (
    <div>
      <Nav className="mr-auto align-items-center">
        <Image
          src={GPS}
          className="icon pointer"
          onClick={() => setShowLocationModal(true)}
        />
        {/* <h2
          style={styles.modalClick}
          onClick={() => setShowLocationModal(true)}
          className="pointer hide-xs hide-sm"
        >
          {userLocation && userPincode
            ? `${userLocation} , ${userPincode}`
            : t("commonHeader.setLocation")}
        </h2>
         */}
         {userLocation && userPincode ? (
  <h2 style={styles.modalClick} onClick={() => setShowLocationModal(true)} className="pointer hide-xs hide-sm">
    {`${userLocation} , ${userPincode}`}
  </h2>
) : (
  <h2 style={styles.modalClick} onClick={() => setShowLocationModal(true)} className="pointer hide-xs hide-sm">
    {t("commonHeader.setLocation")}
  </h2>
)}
        <Image
          src={arrowDown}
          onClick={() => setShowLocationModal(true)}
          style={{ width: 32, height: 32 }}
          className="pointer hide-xs hide-sm"
        />
      </Nav>
      {showLocationModal && (
        <Modal
          show={showLocationModal}
          onHide={() => setShowLocationModal(false)}
          className="location-modal"
          // backdrop="static"
        >
          <Modal.Body>
            <h2 style={styles.modalTitle}>{t("homeScreen.titleLocation")}</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p style={styles.modalInfo}>{t("homeScreen.paraLocation")}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-column-sm">
              <Button className="btnBlue" onClick={handleLocation}>
                {t("homeScreen.btnDetectLocation")}
              </Button>
              <p className="mb-0 or" style={styles.or}>
                &nbsp;
                {t("homeScreen.or")}&nbsp;
              </p>
              <div className="locationAutoComplete">
                <LoadScript
                  googleMapsApiKey={googleMapsApiKey}
                  libraries={libraries}
                >
                  <Autocomplete
                    onLoad={handleLoad}
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <div id="location-popup">
                      <input
                        id="location-search"
                        type="text"
                        placeholder="Search for places..."
                        className="btnWhite"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </Autocomplete>
                </LoadScript>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default LocationComponent;