import React, { useEffect, useState } from "react";
import Section from "../../Components/Section/Section";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import Heading from "../../Components/Heading/Heading";
import styles from "./PlaceOrder.style";
import { FaArrowRight, FaRegClock } from "react-icons/fa";
import ShopIcon from "../../assets/images/shop2.png";
import OfferIcon from "../../assets/images/percent.png";
import ProductImage from "../../assets/images/aata.png";
import BillingIcon from "../../assets/images/billingInfo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { COD_Payment, Payment_Status, View_Cart } from "../../Api";
import { base_url } from "../../Api/ApiConst";
import { toast } from "react-toastify";
import { CLEAR_CART } from "../../Redux/Cart";
import { useDispatch, useSelector } from "react-redux";
import { SET_PAYMENT_LINK } from "../../Redux/Payment";
import { RootState } from "../../Redux/Store/Store";
import { SET_USER_ADDRESS } from "../../Redux/User";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";

const PlaceOrder: React.FC = () => {

    const { t, i18n } = useTranslation();
  
  const breadcrumbs: string[] = [t("footer.home"), t("cart.cart")];

  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [products, setProducts] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [payment, setPayment] = useState<any>("");

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAddress: any = useSelector((state: RootState) => state.User.UserAddress)

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value);
  };

  const {
    first_name,
    last_name,
    address_str,
    city,
    pincode,
    email,
    mobile,
    state,
    type,
  } = userAddress;

  const Cart_Details = async () => {
    try {
      const response = await View_Cart();
      if (response && response.status === 200) {
        console.log(response.data, "hgggggggggggggggggggggggj");
        setProducts(response.data.cart_details.order.order_list);
        setAmount(response.data.cart_details);
        // setStores(response.data.store_list);
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
    }
  };

  // const PaymentStatus = async () => {
  //   if(payment){
  //     try {
  //       const response = await Payment_Status(payment);
  //       if (response && response.status === 200) {
  //         console.log(response.data, "status--------------->");
  //         // setProducts(response.data.cart_details.order.order_list);
  //         // setAmount(response.data.cart_details);
  //         // setStores(response.data.store_list);
  //       } else {
  //         console.log("Error fetching Cart details");
  //       }
  //     } catch (error: any) {
  //       console.log("Error fetching Cart details", error);
  //     }
  //   }else{
  //     console.log("submit order")
  //   }
  // };

  useEffect(() => {
    Cart_Details();
    // PaymentStatus()
    window.scrollTo(0, 0);
  }, []);

  

  // function initiatePayment() {
  //   const paymentUrl = payment;
  //   const paymentWindow = window.open(paymentUrl, "_blank", "width=800,height=600");
  
  //   if (paymentWindow) {
  //     window.addEventListener('message', (event) => {
  //       if (event.origin !== 'https://rc-epay.esewa.com.np/auth') {
  //         return;
  //       }
  
  //       if (event.origin.includes('payments/esewa/success/')) {
  //         navigate("/thank-you");
  //       } else {
  //         navigate("/confirm-order");
  //       }
  //     });
  //   }
  // }

  const handlePaymentCOD = async (id: any) => {
    if (selectedMethod) {
      try {
        const response = await COD_Payment(
          // first_name,
          // last_name,
          // address_str,
          // city,
          // pincode,
          // email,
          // mobile,
          // state,
          // selectedMethod,
          // id
        );

        if (response && response.status === 200) {
          console.log(response.data, "COD----------->");
          setPayment(response.data.payment_url);
          localStorage.setItem(STORAGE_CONSTANTS.UUID,response.data.payment_url)
          if (selectedMethod === "eSewa") {
            const url:any = response.data.payment_url+"&web=true";
            const paymentWindow = window.open(url, "_self");

            // if (paymentWindow) {
            //   console.log(paymentWindow,"data1---------->")
            //   window.addEventListener('message', (event) => {
            //     alert((event)+"data2---------->")

            //     console.log('FFFFFFFFFFFFFFFFFFFFFFFF', event)

            //     if (event.origin !== 'https://rc-epay.esewa.com.np/auth') return;

            //     const { paymentStatus} = event.data.status;
            //     alert(event.data+"data---------->")
            //     if (paymentStatus === 'COMPLETED') {
            //       alert('Payment was successful:');
            //       navigate("/thank-you");
            //     } else {
            //       alert('Payment failed or was cancelled:');
            //       navigate("/confirm-order");
            //     }
            //   }, false);
            // }
          } else if (selectedMethod === "cod") {
            {selectedMethod === "cod" && toast.success("Order CCOOOODDDDDDD placed successfully")}
            navigate("/thank-you");
          }
          dispatch(CLEAR_CART());

        } else {
          console.log("Error fetching Payment details");
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log("Error fetching Payment details", error);
        toast.error("Failed to place order. Please try again.");
      }
    } else {
      toast.error("Please select a payment method to place your order");
    }
  };

  return (
    <>
      <Section className="viewCart">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="py-4">
            <Heading text="Your Cart" alignment="left" />
          </Row>
        </Container>
        <div style={styles.whiteWrap}>
          <Container className="below">
            <Row style={styles.cartFirst}>
              <Col sm={7} style={styles.cartLeftCol}>
                <div style={styles.cartTitle}>
                  <p className="mb-1"> Get it by </p>
                  <h3 className="mb-1" style={styles.day}>
                    Monday
                  </h3>
                  <h3 className="mb-1" style={styles.deliveryCharge}>
                    {" "}
                    रु40.00 Delivery
                  </h3>
                  <h2 className="mb-1" style={styles.deliveryDate}>
                    <b> Guaranteed Delivery </b> on 11 Dec 2023
                  </h2>
                </div>
                <div style={styles.cartItem}>
                  {/* <h3 style={styles.cartTitle}>Shipping From</h3> */}
                  {Object.values(products).map((item: any) =>
                    item.map((ele: any) => {
                      console.log(ele, "ele-------->");
                      return (
                        <Row
                          className="d-flex align-items-center "
                          style={styles.oneCart}
                        >
                          <Col sm={3}>
                            <div style={styles.productImageWrap}>
                              <Image
                                src={
                                  `${base_url}/${ele?.product?.product_image}` ||
                                  ProductImage
                                }
                                style={styles.productImage}
                              />
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div style={styles.productNameWt}>
                              <h3 style={styles.productName}>
                                {ele?.product?.product_name}
                              </h3>
                              <h2 style={styles.productWt}>
                                {ele?.product?.PRDWeight} kg
                              </h2>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="add-to-cart">
                              <h2 style={styles.cartQty}>
                                {" "}
                                Qty : {ele?.quantity}
                              </h2>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div style={styles.productPrice}>रु{ele?.price}</div>
                          </Col>
                        </Row>
                      );
                    })
                  )}
                </div>
              </Col>
              <Col sm={5} style={styles.cartRightCol}>
                <h3 style={styles.cartTitle}>Order Summary</h3>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span style={styles.value500}>
                    Sub Total ({amount?.cart_items} items)
                  </span>
                  <span style={styles.value600}>रु{amount?.f_total}</span>
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mb-2">
                  <span style={styles.value500}>Delivery Partner Fee</span>
                  <span style={styles.value600}>रु40</span>
                </div> */}
                <hr />
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span style={styles.value600}>Order Total</span>
                  <span style={styles.value600}>रु{amount?.total}</span>
                </div>

                <div>
                  <h3 style={styles.cartTitle}>Delivery Address</h3>
                </div>
                <div style={styles.selectedAddressWrap} className="py-4">
                  <div style={styles.selectedAddressText}>
                    <div
                      style={styles.selectedAddressTitle}
                    >{`${first_name} ${last_name}`}</div>
                    {address_str}
                    <br></br>
                    {`${city},${pincode}`}
                    <br></br>
                    {mobile}
                  </div>
                </div>
                <div style={styles.paymentMethodWrap} className="mt-3">
                  <Form className="paymentRadio">
                    <Form.Group as={Col} controlId="paymentMethod">
                      <Form.Label style={styles.cartTitle}>
                        Select Payment Method:
                      </Form.Label>
                      <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                          inline
                          label="Online"
                          type="radio"
                          id={`inline-radio-1`}
                          value="eSewa"
                          checked={selectedMethod === "eSewa"}
                          onChange={handleMethodChange}
                        />
                        <Form.Check
                          inline
                          label="Cash On Delivery"
                          type="radio"
                          id={`inline-radio-2`}
                          value="cod"
                          checked={selectedMethod === "cod"}
                          onChange={handleMethodChange}
                        />
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="justify-content-center d-flex">
                  <Button
                    className="btnBlue"
                    style={{ minWidth: 250 }}
                    onClick={() => handlePaymentCOD(amount?.order?.id)}
                  >
                    Place Order
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default PlaceOrder;
