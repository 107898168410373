import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Table, Image, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import { getStyles } from "./AddProductFromSystem.style";
import { Add_Product, Master_Product } from "../../Api";
import { setSyntheticLeadingComments } from "typescript";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { base_url } from "../../Api/ApiConst";

type FormControlElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

const AddProductFromSystem: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<any>([]);

  const navigate = useNavigate();

  // State for form validation errors
  const [errorss, setErrorss] = useState({
    products: "",
  });

  const [master, setMaster] = useState<any[]>([]);
  const [userToken, setUserToken] = useState("");

  const handleNextStep = () => {
    const newErrors = { ...errorss };

    // Check if products are selected
    if (!formData || formData.length === 0) {
      // If no products are selected, set the error message
      newErrors.products = "Please select at least one product.";
      setErrorss(newErrors);
    } else {
      // If products are selected, clear the error message and proceed to the next step
      newErrors.products = "";
      setErrorss(newErrors);
      setStep(2);
    }
  };

  const handleDelete = (id: any) => {
    setFormData((prevData: any) =>
      prevData.filter((ele: any) => ele.product.id !== id)
    );
  };

  const handlePreviousStep = () => {
    setStep(1);
    setFormData("");
  };

  const fetchMaster = async () => {
    try {
      const response = await Master_Product();
      if (response && response.status === 200) {
        // console.log(
        //   "fetchCategories------>",
        //   response.data.master_product_list
        // );
        setMaster(response.data.master_product_list);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.log("Error fetching categories", error);
    }
  };

  useEffect(() => {
    fetchMaster();
  }, []);

  const handleEditInput = (selectedProduct: any, e: any) => {
    let { name, value } = e.target;

    console.log(name, "------", value);

    let objectToUpdate: any = {};
    objectToUpdate[name] = value;

    console.log(objectToUpdate, "objectToUpdate");

    setFormData((prev: any) => {
      const something = prev.map((prevItem: any) => {
        if (prevItem.id == selectedProduct?.id) {
          return {
            id: prevItem?.id,
            name: prevItem?.name,
            product: { ...prevItem?.product, ...objectToUpdate },
          };
        }
        return prevItem;
      });
      return something;
    });
  };

  const handleSubmits = async (data: any) => {
    console.log(data, "data======>");
    if (data) {
      try {
        const response = await Add_Product(data);
        if (response && response.status === 200) {
          toast.success(response["data"]["message"]);
          console.log(response.data);
          navigate("/products");
        } else {
          console.log(response.data);
        }
      } catch (error: any) {
        console.error("Error occurred:", error.response.data);
        // setStoreError(error.response.data);
      }
    }
  };

  const handleChangePrice = (productId: any, PRDPrice: any) => {
    setFormData((prevSelectedProductList: any) => {
      const updatedData = prevSelectedProductList.map((productItem: any) => {
        if (productItem?.product?.id == productId) {
          return {
            ...productItem,
            product: {
              ...productItem.product,
              PRDPrice: PRDPrice,
            },
          };
        }
        return productItem;
      });
      return updatedData;
    });
  };

  const handleChangeSku = (productId: any, sku: any) => {
    setFormData((prevSelectedProductList: any) => {
      const updatedData = prevSelectedProductList.map((productItem: any) => {
        if (productItem?.product?.id == productId) {
          return {
            ...productItem,
            product: {
              ...productItem.product,
              sku: sku,
            },
          };
        }
        return productItem;
      });
      return updatedData;
    });
  };

  const handleChangeThreshold = (productId: any, threshold_limit: any) => {
    setFormData((prevSelectedProductList: any) => {
      const updatedData = prevSelectedProductList.map((productItem: any) => {
        if (productItem?.product?.id == productId) {
          return {
            ...productItem,
            product: {
              ...productItem.product,
              threshold_limit: threshold_limit,
            },
          };
        }
        return productItem;
      });
      return updatedData;
    });
  };

  const handleChangeInventory = (productId: any, available: any) => {
    setFormData((prevSelectedProductList: any) => {
      const updatedData = prevSelectedProductList.map((productItem: any) => {
        if (productItem?.product?.id == productId) {
          return {
            ...productItem,
            product: {
              ...productItem.product,
              available: available,
            },
          };
        }
        return productItem;
      });
      return updatedData;
    });
  };

  const handleAddProductFromSystem = async () => {
    try {
      const promises = formData.map(async (ele: any) => {
        console.log("ele---------------------------->", ele);

        try {
          setLoading(true)
          console.log("Raw PRDWeight:", ele?.product?.PRDWeight, typeof ele?.product?.PRDWeight);
          const productInformation = {
            productName: ele?.product?.product_name,
            description: ele?.product?.product_description ?? "",
            category: ele?.product?.product_subcategory?.id,
            inventory: ele?.product?.available ?? 0,
            priceInNepali: ele?.product?.PRDPrice ?? 0,
            unitOfMeasure: ele?.product?.unit_measure ?? "",
            price: ele?.product?.USDPrice ?? 0,
            productNameInNepali: ele?.product?.language_data[0]?.product_name ?? "",
            descriptionInNepali: ele?.product?.language_data[0]?.product_description ?? "",
            minQuantity: ele?.product?.minimum_qty ?? 0,
            maxQuantity: ele?.product?.maximum_qty ?? 0,
            variants:ele?.product?.PRDWeight ? ele?.product?.PRDWeight : 0,
            sku: ele?.product?.sku ?? 0,
            brand: ele?.product?.brand ?? "",
            threshold_limit: ele?.product?.threshold_limit ?? 0,
          };

          console.log(productInformation, "PInfo");
          const response: any = await Add_Product(productInformation);
          console.log("response--->", response);
          if (response && response.status === 200) {
            toast.success(response?.data?.message);
            navigate("/products");
          }
          return response;
        } catch (error: any) {
          if (error?.response?.data?.error_message) {
            toast.error(error?.response?.data?.error_message);
          }
          console.log(
            "error while adding system product",
            error?.response?.data
          );
        }
      });

      // Wait for all promises to resolve
      const responses = await Promise.all(promises);
      console.log("All responses:", responses);
    } catch (error) {
      console.log("error while system upload", error);
    } finally {
      setLoading(() => false)
    }
  };

  // console.log(formData.length, "foremDatat-============>");

  return (
    <>
      {step === 1 && (
        <>
          <Row className="mb-3">
            <Form.Group as={Col} md={12} controlId="selectProduct">
              <Form.Label>{t("product.selectProducts")} </Form.Label>
              <Multiselect
                options={
                  master &&
                  master.map((item) => ({
                    id: item.id,
                    name: item.product_name,
                    product: item,
                  }))
                }
                displayValue="name"
                onSelect={(selectedList: any) => setFormData(selectedList)}
                onRemove={(selectedList: any) =>
                  setFormData({
                    ...formData,
                    products: selectedList.map((item: any) => item.name),
                  })
                }
                placeholder={t("product.selectProducts")}
              />
              <Form.Text className="text-danger">{errorss.products}</Form.Text>
            </Form.Group>
          </Row>
          <div className="align-items-center justify-content-end d-flex">
            <Button
              variant="primary"
              onClick={handleNextStep}
              className="btnBlue submit"
            >
              {t("userProfile.next")}
            </Button>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          {/* <div style={styles.customRow}>
            <h3 style={styles.productName}>
              {formData.map((item: any) => {
                return (
                  <ul>
                    <li>{item.product.product_name}</li>
                  </ul>
                );
              })}
            </h3>
            <Button style={styles.remove} onClick={handlePreviousStep}>
              <FontAwesomeIcon icon={faTrashAlt} /> {t("userProfile.remove")}
            </Button>
          </div> */}
          <div className="table-responsive">
            <Table className="productList table" hover>
              <tr>
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Inventory</th>
                <th>SKU*</th>
                <th>Threshold limit*</th>

              </tr>
              {formData.map((ele: any, index: number) => {
                const productDetails: any = ele?.product;
                console.log("productDetails", ele);
                return (
                  <tr>
                    <td className="hide-xs">
                      <div className="imgWrap">
                        <Image
                          src={`${base_url}/${productDetails.product_image}`}
                          className="prdImage"
                        />
                      </div>
                    </td>
                    <td>
                      {" "}
                      <p>{productDetails.product_name}</p>
                    </td>
                    <td>
                      <input
                        className="form-control"
                        value={productDetails?.PRDPrice}
                        onChange={(e) =>
                          handleChangePrice(productDetails?.id, e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        value={productDetails?.available}
                        onChange={(e) =>
                          handleChangeInventory(productDetails?.id, e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        value={productDetails?.sku}
                        onChange={(e) =>
                          handleChangeSku(productDetails?.id, e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="form-control"
                        value={productDetails?.threshold_limit}
                        onChange={(e) =>
                          handleChangeThreshold(productDetails?.id, e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Button
                        style={styles.remove}
                        onClick={() => handleDelete(productDetails?.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt as any} />
                        <span className="hide-xs"> {t("userProfile.remove")} </span>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </div>
          <div className="align-items-center justify-content-between d-flex">
            <Button
              variant="secondary"
              onClick={handlePreviousStep}
              className="submit"
              style={styles.btnCancel}
            >
              {t("userProfile.previous")}
            </Button>

            <button
              onClick={handleAddProductFromSystem}
              className="btnBlue submit"
              disabled={isLoading}
            >
              {isLoading
                ? <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : t("commonFormLabels.submit")
              }
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AddProductFromSystem;
