// import logo from "./logo.svg";
import { BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Index from "./routes/index";
import React, { useCallback, useEffect, useState } from "react";

import i18 from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import { useTranslation } from "react-i18next";
import en from "./shared/localization/en.json";
import ne from "./shared/localization/ne.json";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getToken, onMessage } from 'firebase/messaging';
import Test from '../src/assets/images/avtar.png';
import { getFirebaseMessaging} from './firebase/firebaseConfig';
import { CustomNotificationPayload } from './types/NotificationPayload';


const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("✅ Service Worker registered:", registration);
    } catch (error) {
      console.error("❌ Service Worker registration failed:", error);
    }
  }
};

const requestPermission = async (messaging:any) => {
  const permission = await Notification.requestPermission();
  const vapidKey =process.env.REACT_APP_VAPID_KEY;
  if (permission === "granted") {
    try {
      if (messaging) {
        const currentToken = await getToken(messaging, {
          vapidKey: vapidKey,
        });
        if (currentToken) {
          console.log("🔥 FCM Token:", currentToken);
        } else {
          console.warn("⚠️ No registration token available.");
        }
      } else {
        console.warn("⚠️ Firebase Messaging is null. Retrying...");
        setTimeout(requestPermission, 2000); // Retry after 2 seconds
      }
    } catch (error) {
      console.error("🚨 Error getting FCM token:", error);
    }
  }
};



//Local language imported files
const resources: any = {
  en: { translation: en },
  ne: { translation: ne },
};

//Set Initial language to application
i18.use(initReactI18next).init({
  resources,
  compatibilityJSON: "v3",
  lng: "en", //Set default language
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});



function App() {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    initializeMessaging();
  }, []);

  const initializeMessaging = async () => {
    try {
      await registerServiceWorker();
      
      const messaging = await getFirebaseMessaging();
      if (!messaging) return;

      const permission = await requestPermission(messaging);

      try {
        onMessage(messaging, (payload) => {
          console.log("📩 Foreground notification received:", payload);
          const { title, body } = payload.notification || {};
          toast(<div>
            <strong>{title}</strong>
            <p>{body}</p>
          </div>);
        });
      } catch (error) {
        console.error("Error getting FCM token:", error);
      }
    } catch (error) {
      console.error("Error initializing messaging:", error);
    }
  };



  return (

    <div className="App">
      <I18nextProvider i18n={i18}>
        <BrowserRouter>
          <Index />
        </BrowserRouter>
      </I18nextProvider>
      <ToastContainer />
      </div>
  );
}

export default App;
