import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllNotifications, markNotificationAsRead } from "../../Api";
import { RootState } from "../../Redux/Store/Store";
import lottieEmptyCart from "../../assets/lotties/emptyCart.json";
import { Player } from "@lottiefiles/react-lottie-player";
import NoDataFound from "../NoDataFound/NoDataFound";
import { Col, Row } from "react-bootstrap";

interface NotificationItem {
  id: number;
  message: string;
  created_at: string; // ISO date string
  is_read: boolean;
}

interface NotificationProps {
  notifications: NotificationItem[];
  loading: boolean;
}

const Notification: React.FC<NotificationProps> = ({ notifications, loading }) => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const navigate = useNavigate();
  const role = useSelector((state: RootState) => state.User.ActiveRole); // Get the active role from Redux state
  const [notificationsList, setNotificationsList] = useState<NotificationItem[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [loadings, setLoadings] = useState<boolean>(false);

  // Determine the parameters based on role
  const getNotificationParams = () => {
    if (role === "user") {
      return {
        // unread: true,
        storeNotification: false,
        userNotification: true,
      };
    } else if (role === "store") {
      return {
        // unread: true,
        storeNotification: true,
        userNotification: false,
      };
    }
    // Default case in case role is undefined
    return {
      unread: true,
      storeNotification: false,
      userNotification: true,
    };
  };
  // Fetch only unread notification count
  const fetchUnreadCount = async () => {
    try {
      const params = { ...getNotificationParams(), unread: true };
      const data = await getAllNotifications(params);
      setUnreadCount(data.notification_list?.length || 0);
    } catch (err) {
      console.error("Error fetching unread count:", err);
    }
  };


  // Fetch notifications
  const  fetchNotifications = async () => {
    setLoadings(true);
    try {
      const params = getNotificationParams();
      const data = await getAllNotifications(params);
      setNotificationsList(data.notification_list || []);
      setUnreadCount(data.notification_list?.filter((n: NotificationItem) => !n.is_read).length || 0);
    } catch (err) {
      console.error("Error fetching notifications:", err);
    } finally {
      setLoadings(false);
    }
  };

   // Fetch unread count when the component mounts
   useEffect(() => {
    fetchUnreadCount();
  }, [role]);

  // useEffect(() => {
  //   fetchNotifications(); // Fetch notifications when the component mounts or role changes
  // }, [role]);

  const handleNotificationClick = async (notificationId: number) => {
    try {
      await markNotificationAsRead(notificationId);
      setNotificationsList((prev) =>
        prev.map((note) => (note.id === notificationId ? { ...note, is_read: true } : note))
      );
      setUnreadCount((prev) => Math.max(0, prev - 1)); // Decrease unread count dynamically
    } catch (err) {
      console.error("Error marking notification as read:", err);
    }
  };
  return (
    <div
      className="notification-container"
      onMouseEnter={() => {
        setShowNotification(true);
        fetchNotifications(); // Fetch notifications on hover
      }}
      onMouseLeave={() => setShowNotification(false)}
      style={{ position: "relative", cursor: "pointer" }}
    >
      {/* Bell Icon */}
      <FontAwesomeIcon icon={faBell} size="lg" />

      {/* Notification Count Badge */}
      {unreadCount > 0 && (
        <div
          style={{
            color: "white",
            position: "absolute",
            right: -15,
            top: -15,
            width: "1.5rem",
            height: "1.3rem",
            borderRadius: "50%",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {unreadCount}
        </div>
      )}

      {/* Notification Dropdown */}
      {showNotification && (
        <div className="notification-dropdown">
          {/* Title with Blue Background */}
          <div className="notification-heading">Notifications</div>

          <div
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              padding: "0rem",
            }}
          >

{loading && !notificationsList && (
                <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                        <Player
                            src={lottieEmptyCart}
                            loop
                            autoplay
                            className="player"
                            style={{ height: "100px", width: "100px" }}
                        />
                    </Col>
                </Row>
            )}
            {loading ? (
              <p>Loading...</p>
            ) : notificationsList.length === 0 ? (
              <div className="text-center">
                    <NoDataFound title="No New Notification" />
                </div>
            ) : (
              <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                {notificationsList.map((note) => (
                  <li
                    key={note.id}
                    className="notification-item"
                    onClick={() => handleNotificationClick(note.id)} // Handle the click to mark as read
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0.8rem",
                      borderBottom: note.is_read ? "2px solid #e0eefe" : "2px solid #fff",
                      backgroundColor: note.is_read ? "#fff" : "#e0eefe",
                    }}
                  >
                    <div
                      style={{
                        minWidth: "2.5rem",
                        width: "2.5rem",
                        height: "2.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: note.is_read ? "#e0eefe" : "#fff",
                        borderRadius: "50%",
                        marginRight: "1rem",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBell}
                        size="lg"
                        style={{ color: "#007bff" }}
                      />
                    </div>

                    {/* Message and Timing */}
                    <div style={{ flexGrow: 1 }}>
                      <p
                        style={{
                          margin: 0,
                          fontWeight: note.is_read ? "medium" : "bold",
                        }}
                      >
                        {note.message}
                      </p>
                      <span className="ago">{moment(note.created_at).fromNow()}</span>
                    </div>

                    {/* Green Dot for Unread */}
                    {!note.is_read && (
                      <span
                        style={{
                          minWidth: "0.6rem",
                          width: "0.6rem",
                          height: "0.6rem",
                          borderRadius: "100%",
                          backgroundColor: "var(--primary-color)",
                          marginLeft: "0.5rem",
                        }}
                      ></span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* View All Notifications Link */}
          <div
            style={{
              textAlign: "center",
              padding: "0.8rem",
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <button
              onClick={() => navigate("/notifications")}
              style={{
                background: "none",
                border: "none",
                color: "#007bff",
                cursor: "pointer",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              View All Notifications
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
