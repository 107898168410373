import React, { useEffect } from 'react';
import styles from './Footer.style';
import Section from '../Section/Section';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import "react-multi-carousel/lib/styles.css";
import Logo from '../../assets/images/logo--white.png';
// import Logo from '../../assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../Redux/Store/Store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTiktok,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
const Footer: React.FC = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

 const role = useSelector((state: RootState) => state.User.ActiveRole)

 useEffect(() =>{
    const savedLanguage = localStorage.getItem(
        STORAGE_CONSTANTS.CURRENT_SELECTED_LANGUAGE
      );
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
      }
 },[])

    return (

        <Section className="footer" >
            <div style={{ backgroundColor: 'var(--primary-color)', paddingTop: 50, paddingBottom: 50 }}>
                <Container>
                    <Row>
                        <Col sm={3} className="f-col1">
                            <Image src={Logo} className='footer-logo' />
                            <p style={styles.para}>Welcome to Sajilo Life, where our mission is clear: Makes Life Easier. </p>

                            {/* <div className="d-grid gap-2">  */}
                            <Button
                            style={styles.btnFooter}
                            onClick={() => navigate("/contact-us")}
                            >
                                {t("footer.askQuestion")}
                                </Button>
                            {/* </div> */}
                        </Col>
                        <Col sm={3} className="f-col2">
                            <h3 style={styles.footerHeading}>{t("footer.quickLinks")}</h3>
                            <ul style={{ paddingLeft: 0 }}>
                                <li style={styles.navItem}>
                                    <Link to="about-us">{t("footer.aboutUs")}</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="faqs">{t("footer.faq's")}</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="terms-conditions">{t("footer.terms")}</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="privacy-policy">{t("footer.privacy")}</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="contact-us">{t("footer.contactUs")}</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col sm={2} className="f-col3">
                            <h3 style={styles.footerHeading}>{t("footer.navigation")}</h3>
                            <ul style={{ paddingLeft: 0 }}>
                                <li style={styles.navItem}>
                                   { role !== "store" ? <Link to="/">{t("footer.home")}</Link> : <Link to="/store-dashboard">{t("footer.home")}</Link>}
                                </li>
                                <li style={styles.navItem}><Link to="/near-by-products">{t("footer.product")}</Link></li>
                                <li style={styles.navItem}>{t("footer.blog")}</li>
                            </ul>
                        </Col>
                        <Col sm={4} className="f-col4">
                            <h3 style={styles.footerHeading}>{t("footer.contact")}</h3>
                            <p style={styles.para}>{t("footer.colText")}</p>
                            <p style={{...styles.para,marginBottom:0}}>Customer : <a style={{color:"#fff"}} href="mailto:customer.support@sajilolife.com"> customer.support@sajilolife.com</a></p>
                            <p style={{...styles.para,marginTop:0}}>Seller : <a style={{color:"#fff"}} href="mailto:seller.support@sajilolife.com"> seller.support@sajilolife.com</a><br></br><br></br><a style={{color:"#fff"}} href="tel:+9779708073716">+977-9708073716</a></p>

                            <div style={{ display: "flex", gap: "15px" }} className="f-social">
      <a href="https://www.facebook.com/sajilolifeofficial/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a href="https://www.tiktok.com/@sajilolifeofficial" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTiktok as any} size="2x" />
      </a>
      <a href="https://www.instagram.com/sajilolifeofficial/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram as any} size="2x" />
      </a>
      <a href=" https://www.linkedin.com/in/sajilolifeofficial/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin as any} size="2x" />
      </a>
     
    
    </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Section>

    );
};

export default Footer;
