import React, { useState, useEffect, FormEvent } from 'react';
import { Col, Container, Row, Form, Button, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { User_LogIn } from '../../Api';
import { toast } from 'react-toastify';
import Logo from '../../assets/images/logo.png';
import LoginImg from '../../assets/images/login-modal.png';
import { FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { setLoginStatus } from '../../Redux/loginSlice';
import { getStyles } from './Login.style';
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
import { useTranslation } from 'react-i18next';
import { getToken } from 'firebase/messaging';
import { getFirebaseMessaging } from '../../firebase/firebaseConfig';
import "./Login.css";


const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("✅ Service Worker registered:", registration);
    } catch (error) {
      console.error("❌ Service Worker registration failed:", error);
    }
  }
};

const Login: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [number, setNumber] = useState('');
  const [errors, setErrors] = useState({ number: '' });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = getStyles(isMobile);

  useEffect(() => {
    handleFCMToken()
  }, []);

  const fetchFcmToken = async () => {
    try {

    await registerServiceWorker();
    const messaging = await getFirebaseMessaging();
    
    if (!messaging) {
      console.error("no valid messaging");
      return "";
    }
    const vapidKey = process.env.REACT_APP_VAPID_KEY;
console.log("vapidkey",vapidKey)
      const token = await getToken(messaging, {

        vapidKey: vapidKey,
      });
      console.log("🔥 FCM Token:", token);
      return token;
    } catch (error: any) {
      console.error("⚠️ Error getting FCM token:", error.message);
    }
    return ""
  };

  const handleFCMToken = async () => {
    try {
      const retrievedFCMToken:any = await fetchFcmToken();
      setFcmToken(retrievedFCMToken);
    } catch (error) {
      setFcmToken("")
    }
  }
  
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = event.target.value;
    setNumber(newData);
    if (newData === '') {
      setErrors((errors) => ({
        ...errors,
        number: `* ${t("login.enterError")}`
      }));
    } else if (newData.length !== 10) {
      setErrors((errors) => ({
        ...errors,
        number: `* ${t("login.invalidError")}`
      }));
    } else {
      setErrors((errors) => ({ ...errors, number: '' }));
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (number === '') {
      setErrors((errors) => ({
        ...errors,
        number: `* ${t("login.enterError")}`
      }));
    }

    if (number !== '' && number.length === 10) {
      try {
        setBtnLoader(true);
        // const response = await User_LogIn(number);

        const deviceType = 'web';
        let userFcmToken = fcmToken;

        if(!userFcmToken){
          userFcmToken = await fetchFcmToken();
        }

        const response = await User_LogIn({
          mobile_number: number,
          registered_device: userFcmToken,
          device_type: deviceType,
        });
        if (response && response.status === 200) {
          localStorage.setItem(STORAGE_CONSTANTS.USER_ID, response.data.user_id);
          localStorage.setItem(STORAGE_CONSTANTS.USER_OTP, response.data.otp);
          localStorage.setItem(STORAGE_CONSTANTS.NUMBER, number);
          localStorage.setItem(STORAGE_CONSTANTS.USER_NUMBER, response.data.user_mobile_number);
          toast.success(response.data.message);
          // navigate('/verify');
          navigate('/verify', { state: { mobileNumber: number } });

        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        if (error.response) {
          // Check if the error response contains a message
          const errorMessage = error.response.data?.message || "An unexpected error occurred.";
          toast.error(errorMessage); // Display the actual message from the server
        } else {
          console.error(error);
          toast.error("An unexpected error occurred.");
        }
      } finally {
        setBtnLoader(false)
      }
    }
  };

  return (
    <Container fluid style={{ height: '100vh' }}>
      <Row style={{ height: '100%' }} className="sm-login">
        <Col md={6} style={styles.leftColumnStyle}>
        <Link to="/">
            <FaTimes style={styles.closeIcon} className="hide-lg"/>
          </Link>
          <div style={{ textAlign: 'left' }}>
            <Link to="/">
              <Image src={Logo} style={{ width: 224, height: 88 }} />
            </Link>
            <h2 style={styles.formTitle}>{t("login.login")}</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail" style={{ textAlign: 'left' }}>
                <Form.Label style={styles.formLabel}>{t("login.phoneNumber")}</Form.Label>
                <Form.Control
                  type="number"
                  value={number}
                  onChange={handleEmailChange}
                  style={styles.input}
                  className='loginMobInput'
                />
                {errors && <span style={styles.error}>{errors.number}</span>}
              </Form.Group>
              <Button type="submit" style={styles.btnBlue}>
              {t("login.continue")}
              </Button>
            </Form>
            <p style={styles.terms}>
            {t("login.terms")}
            </p>
          </div>
        </Col>
        <Col md={6} style={styles.rightColumnStyle} className='hide-xs hide-sm'>
          <Link to="/">
            <FaTimes style={styles.closeIcon} />
          </Link>
          <img src={LoginImg} alt="Image" style={styles.imageStyle} />
          <h2 style={styles.title}>{t("login.welcomeText")}</h2>
          <p style={styles.para}>Everything you need at single platform</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
