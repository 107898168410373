import React, { useEffect, useState } from "react";
import { Nav, Container, Row, Col, Image, Button } from "react-bootstrap";
import Section from "../../Components/Section/Section";
import Heading from "../../Components/Heading/Heading";
import { getStyles } from "./StoreDashboard.style";
import { FaArrowRight } from "react-icons/fa";
import storeImage from "../../assets/images/shop2.png";
import { Link, useNavigate } from "react-router-dom";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import moment from "moment";
import { My_Store, Store_Revenue } from "../../Api";
import { useTranslation } from "react-i18next";
import { base_url } from "../../Api/ApiConst";
import { toast } from "react-toastify";

const StoreDashboard: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
    const breadcrumbs: string[] = [t("footer.home"), t("footer.store")];
    const title: string = 'Privacy Policy';
  const [storeName, setStoreName] = useState<string | null>("");
  const [label, setLabel] = useState("");
  const [userTokens, setUserTokens] = useState<any>("");
  const [admission, setAdmission] = useState<any>("");
  const [storeRevenue, setStoreRevenue] = useState("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedDateType, setSelectedDateType] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [storeId, setStoreId] = useState<string | null>("");
  const [storeLogo, setStoreLogo] = useState<any>("");

  const [fromDate, setFromDate] = useState<any>(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState<any>(moment().format("YYYY-MM-DD"));

  const navigate = useNavigate();

  const handleGetStoreRevenue = async () => {
    try {
      const payload = {
        from_date: fromDate,
        till_date: toDate,
        store_id: storeId,
      };
      const response: any = await Store_Revenue(payload);

      if (!response?.error) {
        setStoreRevenue(response.data?.revenue_data?.total_amount ?? 0);
        console.log("data----->", response?.revenue_data?.total_amount);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.log(error);
    }
  };

  const handleOnDayPress = (dayObject: any) => {
    const formatedDate = moment(dayObject?.dateString).format("DD-MM-YYYY");

    if (selectedDateType == "from") {
      setFromDate(formatedDate);
    } else {
      setToDate(formatedDate);
    }

    setShowModal(false);
  };

  useEffect(() => {
    const today = moment();
    const isFromDateToday = moment(fromDate, "YYYY-MM-DD").isSame(today, "day");
    const isToDateToday = moment(toDate, "YYYY-MM-DD").isSame(today, "day");

    if (isFromDateToday && isToDateToday) {
      setLabel(t("storeDashboard.todaysSale"));
    } else {
      setLabel(t("storeDashboard.sales"));
    }
    console.log("store id", storeId);
    setShowLoader(true);
    handleGetStoreRevenue();
  }, [fromDate, toDate, storeId]);

  const handleFromDateClick = (dateType: string) => {
    setSelectedDateType(dateType);
    setShowModal(true);
  };

  const toggleModal = () => {
    setSelectedDateType("");
    setShowModal((prev: boolean) => !prev);
  };

  const handleAPI = async (check: any) => {
    const userTokens = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
    console.log("in Handle API", userTokens);

    try {
      const response = await My_Store(userTokens);
      if (response && response.status === 200) {
        console.log(response.data.my_stores[0], "storeImage============>");
        setAdmission(response.data.my_stores[0].admission);
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_NAME,
          response.data.my_stores[0].store_name
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_STATUS,
          response.data.my_stores[0].admission
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_ID,
          response.data.my_stores[0].id
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.STORE_LOGO,
          response.data.my_stores[0].logo
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.AREA_PIN,
          response.data.my_stores[0].service_area_pin_code
        );

        if (response?.data?.my_stores[0]?.admission === "Approve") {
          if (check === "ord") {

            navigate("/orders");
          } else if (check === "prod") {
            navigate("/products");
          }
        } else {
          toast.error("Store is not approved");
        }

      } else {
        console.log("Error fetching My Store details");
      }
    } catch (error) {
      console.log("Error fetching My Store details", error);
    }
  };

  useEffect(() => {
    let userToken = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN)
    if (userToken) {
      handleAPI("none")
    }
  }, [])

  useEffect(() => {
    setStoreName(localStorage.getItem(STORAGE_CONSTANTS.STORE_NAME));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
    setStoreLogo(localStorage.getItem(STORAGE_CONSTANTS.STORE_LOGO));
    setUserTokens(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    // setAdmission(localStorage.getItem(STORAGE_CONSTANTS.STORE_STATUS));
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleOrders = () => {
    handleAPI("ord")

  };

  const handleProducts = async () => {
    handleAPI("prod")
  };


  return (
    <Section className="storeDashboard" style={styles.bg}>
      <Container>
        <Row>
          <div className="breadcrumbs">
            {breadcrumbs.map((crumb, index) => (
              <React.Fragment key={index}>
                <span
                  style={{
                    color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                    fontFamily: "var(--primary-font-medium)",
                    fontSize: 16,
                  }}
                >
                  {crumb}
                </span>
                {index !== breadcrumbs.length - 1 && (
                  <FaArrowRight className="mx-2" style={{ color: "#BEB8B8" }} />
                )}
              </React.Fragment>
            ))}
          </div>
        </Row>
        <Row style={styles.card}>
          <Col>
            <div className="store-dates">
              {t("storeDashboard.from")}{" "}
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              {t("storeDashboard.to")}{" "}
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>

            <p style={styles.text}>{t("storeDashboard.hello")}</p>
            <div style={styles.storeWrap}>
              <div style={styles.storeHeader}>
                <div style={styles.storeImageWrap}>
                  <Image
                    src={
                      storeLogo !== "null" ? `${base_url}${storeLogo}` : storeImage
                    }
                    style={styles.storeImage}
                  />
                </div>
                <div style={styles.storeNameWrap}>
                  <h2 style={styles.storeName}>{storeName}</h2>
                </div>
              </div>
            </div>
            <h2 style={styles.text}>{label}</h2>
            <p style={styles.total}>{storeRevenue}/-</p>
          </Col>
        </Row>
        <Row style={styles.btnWrap}>
          <Col>
            <Button style={styles.btnBlue} onClick={handleOrders}>
              {t("storeDashboard.order")}
            </Button>
          </Col>
          <Col>
            <Button style={styles.btnBlue} onClick={handleProducts}>
              {t("storeDashboard.products")}{" "}
            </Button>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default StoreDashboard;
