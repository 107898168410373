import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Image, Modal, Form } from "react-bootstrap";
import noProduct from "../../assets/images/no-product.png";
import ShopImage from "../../assets/images/default-shop.png";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import styles from "./ProductReviews.style";
import { base_url } from "../../Api/ApiConst";
import { useDispatch, useSelector } from "react-redux";

import productId, { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { Link, useNavigate } from "react-router-dom";
import {
  Add_To_Cart,
  Add_To_Wishlist,
  Brought_Together,
  Delete_Wishlist,
  Past_Orders,
  Product_Ratings,
  Products_for_Review,
  User_Product_Rating,
  View_Cart,
  View_Wishlist,
} from "../../Api";
import { toast } from "react-toastify";
import { VIEW_CART } from "../../Redux/Cart";
import DefaultProduct from "../../assets/images/default-product.png";
import {
  SET_PRODUCT_ID_IN_WISHLIST,
  SET_SELECTED_STORE_SUBCATEGORY,
} from "../../Redux/User";
import {
  AddToWishList,
  fetchWishlist,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";
import { RootState } from "../../Redux/Store/Store";
import Heading from "../Heading/Heading";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rating } from "react-simple-star-rating";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1901 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
const ProductReviews: React.FC = () => {
  const { t, i18n } = useTranslation();

  const languageId: any = useSelector((state: RootState) => state.lan);
  const [userToken, setUserToken] = React.useState<string | null>("");
  const [currency, setCurrency] = useState("NPR");
  const [products, setProducts] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const [showRatingModal, setShowRatingModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [item, setItem] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
  }, []);

  useEffect(() => {
    fetchWishlist();
  }, [userToken]);

  const fetchOrders = async (currentPage: any, limit: any) => {
    try {
      setLoading(true);
      const response = await Products_for_Review(currentPage, limit);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        console.log(response.data, "buyyyy---------->");
        setProducts(response.data.product_list);
      } else {
        console.log("Error fetching Past orders");
      }
    } catch (error) {
      console.log("Error fetching Past orders", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userToken) {
      fetchOrders(currentPage, limit);
    }
  }, [userToken]);

  // const handleClick = (id: any) => {
  //   dispatch(SET_SELECTED_PRODUCT(id));
  //   navigate("/product-details");
  // };

  // const handleCart = (
  //   id: any,
  //   storeId: any,
  //   store_name: any,
  //   language_data: any,
  //   store_logo: any,
  //   subcategory: any
  // ) => {
  //   const addToCart = async () => {
  //     try {
  //       // alert('SSSSSSSSSS'+storeId)
  //       const response = await Add_To_Cart(id, currency);
  //       if (response && response.status === 200) {
  //         // console.log(response.data, "jhhhhhhhhhhhhhhh")
  //         localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, storeId);
  //         localStorage.setItem(
  //           STORAGE_CONSTANTS.ORDER_ID,
  //           response.data?.order_id
  //         );
  //         dispatch(SET_SELECTED_STORE_SUBCATEGORY(subcategory));
  //         toast.success(response["data"]["message"]);
  //         const state = {
  //           store_name: store_name,
  //           language_data: language_data,
  //           store_logo: store_logo,
  //         };
  //         const Cartresponse = await View_Cart();
  //         if (Cartresponse && Cartresponse.status === 200) {
  //           // console.log(Cartresponse.data.cart_details.cart_items, "kkkkkkkkkkkk");
  //           dispatch(VIEW_CART(Cartresponse.data));
  //         }
  //         navigate("/store", { state: state });
  //       } else {
  //         toast.error(response["data"]["message"]);
  //       }
  //     } catch (error: any) {
  //       console.log("Error occurred:", error);
  //     }
  //   };
  //   addToCart();
  // };
  // const manageTextLength = (text: string): string => {
  //   if (text.length > 40) {
  //     let newText = text.slice(0, 30) + "...";
  //     return newText;
  //   } else {
  //     return text;
  //   }
  // };
  // const isInWishlist = (id: any) => {
  //   // return wishlist.includes(id);
  // };

  const handleOpenRatingModal = (product: any) => {
    // setSelectedProduct(product);
    setShowRatingModal(true);
    setItem(product);

    if (product?.id) {
      const OrderRating = async () => {
        try {
          setLoading(true);
          const response = await Product_Ratings(product?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            setSelectedProduct(response?.data?.product_rating_list);
            setComment(response?.data?.product_rating_list[0]?.client_comment);
          } else {
            console.log("Error fetching Past orders");
          }
        } catch (error) {
          console.log("Error fetching Past orders", error);
        } finally {
          setLoading(false);
        }
      };
      OrderRating();
    }
  };

  const handleRate = (rate: number) => {
    console.log("rate", rate);
    setRating(rate);
  };

  const handleSubmit = () => {
    if (rating) {
      const RateProduct = async () => {
        try {
          const payload = {
            rate: rating,
            client_comment: comment,
          };
          const response = await User_Product_Rating(payload, item?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            setSelectedProduct(response?.data?.product_rating_list);
            toast.success(response["data"]["message"]);
            fetchOrders(currentPage, limit);
            setComment("");
            handleCloseModal();
          } else {
            console.log("Error fetching Past orders");
          }
        } catch (error: any) {
          console.log("Error fetching Past orders", error);
          toast.error(error?.response?.data?.message);
        }
      };
      RateProduct();
    } else {
      toast.error("Please rate the product");
    }
  };

  const handleCloseModal = () => {
    setShowRatingModal(false);
    setComment("");
  };

  console.log(item, "item========>");

  return (
    <>
      {products.length > 0 && (
        <>
          <Row className="align-items-center justify-content-between  mb-3 prod-reviews">
            <Col>
              <Heading
                text={t("userProfile.productReviews")}
                alignment="left"
              />
            </Col>
            <Col>
              <p style={styles.viewAll} className="mb-0">
                <Link to="/product-reviews">View All</Link>
              </p>
            </Col>
          </Row>
          <div className="global-products mt-3 productReviews">
            <Row className="mt-3">
              <Col>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={"tablet"}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {products &&
                    products.map((product, index) => (
                      <div key={index} className="global20">
                        <div
                          style={styles.product}
                          className="product-card"
                          onClick={() => handleOpenRatingModal(product)}
                        >
                          <div
                            style={{
                              ...styles.imageWrap,
                              backgroundColor: "#fff",
                            }}
                            key={product.id}
                            className="product-image-wrap"
                          >
                            <Image
                              className="product-image"
                              src={`${base_url}${product?.product_image}`}
                              style={styles.productImage}
                              onClick={() => handleOpenRatingModal(product)}
                              onError={(e) => (e.currentTarget.src = DefaultProduct)} // Replace with default image
                            />
                          </div>

                          <div
                            style={styles.productContent}
                            className="productContent"
                          >
                            <div className="d-flex align-items-center mb-2">
                              <Image
                                src={
                                  product?.product_vendor_store?.logo
                                    ? `${base_url}/${product?.product_vendor_store?.logo}`
                                    : ShopImage
                                }
                                style={styles.shopIcon}
                              />
                              <h2 style={styles.shopName} className="mb-0">
                                {product?.product_vendor_store?.language_data
                                  ?.length === 0
                                  ? product?.product_vendor_store?.store_name
                                  : languageId === "ne"
                                    ? product?.product_vendor_store
                                      ?.language_data[0]?.store_name
                                    : product?.product_vendor_store?.store_name ||
                                    "Unknown Store"}
                              </h2>
                            </div>
                            <h2
                              style={styles.productName}
                              className="product-name"
                              onClick={() => handleOpenRatingModal(product)}
                            >
                              {product?.language_data?.length > 0
                                ? languageId === "ne"
                                  ? product?.language_data[0]?.product_name
                                  : product?.product_name
                                : product?.product_name}
                            </h2>
                            <div>
                              <Rating
                                initialValue={product?.product_rating}
                                allowHover={false}
                                readonly={true}
                                size={22}
                                style={{ marginBottom: "7rem" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Carousel>
              </Col>
            </Row>
            {
              <Modal
                show={showRatingModal}
                onHide={handleCloseModal}
                className="ratingModal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{t("userProfile.shareReview")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex align-items-center mb-3">
                    <Image
                      src={
                        item?.product_image
                          ? `${base_url}/${item?.product_image}`
                          : ShopImage
                      }
                      style={{
                        width: 50,
                        height: 50,
                        marginRight: 10,
                        borderRadius: 10,
                      }}
                    />
                    <h5 className="mb-0">
                      {item?.language_data?.length > 0
                        ? languageId === "ne"
                          ? item?.language_data[0]?.product_name
                          : item?.product_name
                        : item?.product_name || "Unknown Product"}
                    </h5>
                  </div>
                  <div className="mb-3">
                    <Rating
                      initialValue={selectedProduct && selectedProduct[0]?.rate}
                      allowHover={false}
                      readonly={
                        selectedProduct && selectedProduct[0]?.rate
                          ? true
                          : false
                      }
                      size={33}
                      style={{ marginBottom: "7rem" }}
                      onClick={handleRate}
                    />
                  </div>
                  <Form.Group controlId="formComment">


                    {selectedProduct && selectedProduct[0]?.rate ? (
                      comment ? (
                        <p className="mb-2" style={{ background: "#fff", padding: 10, border: "1px solid #dee2e6", borderRadius: 10 }}>{comment}</p>
                      ) : null
                    ) : (
                      <>   <Form.Label>{t("userProfile.comment")}</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </>
                    )}

                  </Form.Group>
                  <br />
                  {selectedProduct && selectedProduct[0]?.rate ? (
                    <h6>{t("userProfile.ratingThankYouMessage")}</h6>
                  ) : (
                    ""
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="btnCancel"
                    onClick={handleCloseModal}
                  >
                    {t("deleteModal.cancel")}
                  </Button>
                  {selectedProduct && selectedProduct[0]?.rate ? (
                    ""
                  ) : (
                    <Button
                      variant="primary"
                      className="btnBlue"
                      onClick={() => handleSubmit()}
                      disabled={
                        selectedProduct && selectedProduct[0]?.rate
                          ? true
                          : false
                      }
                    >
                      {t("commonFormLabels.submit")}
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            }
          </div>
        </>
      )}
    </>
  );
};

export default ProductReviews;
