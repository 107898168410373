import React, { useEffect } from 'react';
import { Container, Tabs, Tab, Accordion } from 'react-bootstrap';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/Faq.png';
import Section from '../../Components/Section/Section';
import { useTranslation } from 'react-i18next';

const Faq: React.FC = () => {
  const { t } = useTranslation(); // For translation

  const breadcrumbs: string[] = [t("footer.home"), t("footer.faq's")];
  const title: string = 'Frequently asked questions';
  const info: string = ' ';
  const imageUrl: string = TitleImage; // Corrected imageUrl

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Accordion for displaying FAQ items
  const FaqAccordion: React.FC<{ items: { question: string; answer: string }[] }> = ({ items }) => (
    <Accordion>
      {items.map((item, index) => (
        <Accordion.Item eventKey={`${index}`} key={index}>
          <Accordion.Header>{item.question}</Accordion.Header>
          <Accordion.Body>
          <p dangerouslySetInnerHTML={{ __html: item.answer.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") }} />            
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );

  // Tabs to render different FAQ categories
  const FaqTabs: React.FC = () => {
    const faq = {
      general: [
        { question: t('faq.general.q1'), answer: t('faq.general.a1') },
        { question: t('faq.general.q2'), answer: t('faq.general.a2') }
      ],
      orderingAndPayments: [
        { question: t('faq.orderingAndPayments.q1'), answer: t('faq.orderingAndPayments.a1') },
        { question: t('faq.orderingAndPayments.q2'), answer: t('faq.orderingAndPayments.a2') },
        { question: t('faq.orderingAndPayments.q3'), answer: t('faq.orderingAndPayments.a3') },
        { question: t('faq.orderingAndPayments.q4'), answer: t('faq.orderingAndPayments.a4') },
        { question: t('faq.orderingAndPayments.q5'), answer: t('faq.orderingAndPayments.a5') }
      ],
      shippingAndDelivery: [
        { question: t('faq.shippingAndDelivery.q1'), answer: t('faq.shippingAndDelivery.a1') },
        { question: t('faq.shippingAndDelivery.q2'), answer: t('faq.shippingAndDelivery.a2') },
        { question: t('faq.shippingAndDelivery.q3'), answer: t('faq.shippingAndDelivery.a3') },
        { question: t('faq.shippingAndDelivery.q4'), answer: t('faq.shippingAndDelivery.a4') },
        { question: t('faq.shippingAndDelivery.q5'), answer: t('faq.shippingAndDelivery.a5') }
      ],
      accountManagement: [
        { question: t('faq.accountManagement.q1'), answer: t('faq.accountManagement.a1') },
        { question: t('faq.accountManagement.q2'), answer: t('faq.accountManagement.a2') },
        { question: t('faq.accountManagement.q3'), answer: t('faq.accountManagement.a3') },
        { question: t('faq.accountManagement.q4'), answer: t('faq.accountManagement.a4') },
        { question: t('faq.accountManagement.q5'), answer: t('faq.accountManagement.a5') }

      ],
      customerSupport: [
        { question: t('faq.customerSupport.q1'), answer: t('faq.customerSupport.a1') },
        { question: t('faq.customerSupport.q2'), answer: t('faq.customerSupport.a2') },
        { question: t('faq.customerSupport.q3'), answer: t('faq.customerSupport.a3') },
        { question: t('faq.customerSupport.q4'), answer: t('faq.customerSupport.a4') }
      ],
      refundReturnAndCancellation: [
        { question: t('faq.refundReturnAndCancellation.q1'), answer: t('faq.refundReturnAndCancellation.a1') },
        { question: t('faq.refundReturnAndCancellation.q2'), answer: t('faq.refundReturnAndCancellation.a2') },
        { question: t('faq.refundReturnAndCancellation.q3'), answer: t('faq.refundReturnAndCancellation.a3') },
        { question: t('faq.refundReturnAndCancellation.q4'), answer: t('faq.refundReturnAndCancellation.a4') },
        { question: t('faq.refundReturnAndCancellation.q5'), answer: t('faq.refundReturnAndCancellation.a5') },
        { question: t('faq.refundReturnAndCancellation.q6'), answer: t('faq.refundReturnAndCancellation.a6') },
        { question: t('faq.refundReturnAndCancellation.q7'), answer: t('faq.refundReturnAndCancellation.a7') },
        { question: t('faq.refundReturnAndCancellation.q8'), answer: t('faq.refundReturnAndCancellation.a8') },
        { question: t('faq.refundReturnAndCancellation.q9'), answer: t('faq.refundReturnAndCancellation.a9') },
        { question: t('faq.refundReturnAndCancellation.q10'), answer: t('faq.refundReturnAndCancellation.a10') }
      ]
    };

    return (
      <Tabs defaultActiveKey="general" id="faq-tabs">
        <Tab eventKey="general" title={t('faq.general.title')}>
          <FaqAccordion items={faq.general} />
        </Tab>
        <Tab eventKey="orderingAndPayments" title={t('faq.orderingAndPayments.title')}>
          <FaqAccordion items={faq.orderingAndPayments} />
        </Tab>
        <Tab eventKey="shippingAndDelivery" title={t('faq.shippingAndDelivery.title')}>
          <FaqAccordion items={faq.shippingAndDelivery} />
        </Tab>
        <Tab eventKey="accountManagement" title={t('faq.accountManagement.title')}>
          <FaqAccordion items={faq.accountManagement} />
        </Tab>
        <Tab eventKey="customerSupport" title={t('faq.customerSupport.title')}>
          <FaqAccordion items={faq.customerSupport} />
        </Tab>
        <Tab eventKey="refundReturnAndCancellation" title={t('faq.refundReturnAndCancellation.title')}>
          <FaqAccordion items={faq.refundReturnAndCancellation} />
        </Tab>
      </Tabs>
    );
  };

  return (
    <div className="faqs">
      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="faqs" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
          <FaqTabs />
        </Container>
      </Section>
    </div>
  );
};

export default Faq;
