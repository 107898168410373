import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Spinner,
  Overlay,
  InputGroup,
  Form,
  Modal,
  DropdownButton,
  Badge,
} from "react-bootstrap";
import { getStyles } from "./OrderList.style";
import Section from "../../Components/Section/Section";
import {
  FaAngleRight,
  FaArrowRight,
  FaRegClock,
  FaSearch,
  FaSort,
} from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/default-shop.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchOrderDetailsStatus,
  fetchSellerCancellationReasons,
  getOrderDetails,
  My_Store,
  Order_list,
  Search_Store_Orders,
  Search_Store_Orders_Suggestions,
  sellerCancelLineItem,
  Sort_Order_list,
  Store_Product_Details,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import FormControl from "react-bootstrap/esm/FormControl";
import Popover from "react-bootstrap/esm/Popover";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import debounce from "lodash.debounce";
import Dropdown from "react-bootstrap/esm/Dropdown";

interface DropdownOption {
  label: string;
  value: string;
}

interface Suggestion {
  id: number;
  name: string;
  img?: string;
}

const OrderList: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);

  const breadcrumbs: { text: string; link?: string }[] = [
    { text: t("footer.home"), link: "/" },
    { text: t("orderList.orders") },
  ];

  const handleSearch = () => {
    fetchSearchOrders("");
    if (searchText === "") {
      setIsSearchResult(false);
      return handleAPI();
    }
    setIsSearchResult(true);
  };

  interface Category {
    id: string;
    amount: string;
    order_date: string;
    // order_date: string;
    // PRDPrice: number;
    // PRDWeight: string;
    // product_subcategory: any;
    // name: string
    order_details: string;
    product: string;
    product_image: string;
    status: string;
  }

  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [image, setImage] = useState<string | null>("");
  const [product, setProduct] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pages, setPages] = useState<any>({});
  const [store, setStore] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false); // Track if the current view is search results
  const [storeLogo, setStoreLogo] = useState<any>(null);
  const [showUpdateOrderModal, setShowUpdateOrderModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState<string | null>(null);
  const [cancellationReason, setCancellationReason] = useState<string | null>(
    null
  );
  const [reasonOptions, setReasonOptions] = useState<string[]>([]);
  const [otherCancelReason, setOtherCancelReason] = useState<string | null>(
    null
  );
  const [orderDetails, setOrderDetails] = useState<any>(null); // Adjust type based on your response
  const [error, setError] = useState<string | null>(null);
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null);
  const [statusList, setStatusList] = useState<DropdownOption[]>([]);
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [productStatuses, setProductStatuses] = useState<{
    [key: number]: string;
  }>({});
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [value, setValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>(
    []
  );
  const [deliveryMethod, setDeliveryMethod] = useState<string | null>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [productIds, setProductIds] = useState<number[]>([]); // State to store product IDs
  const [orderId, setOrderId] = useState<string | null>(null);
  const [testKey, setTestKey] = useState<any>(0);
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Ref for the dropdown

  // const handleSort = (criteria: string) => {
  //   // Implement your sorting logic here
  //   setSortBy(criteria);
  // };

  const sortData = [
    {
      id: 1,
      Sorttype: "id",
      name: t("storeSortModal.productNameAsc"),
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "-id",
      name: t("storeSortModal.productNameDesc"),
      Ascending: false,
    },
    {
      id: 3,
      Sorttype: "purchase_date",
      name: 'purchase_date(Ascending)',
      Ascending: true,
    },
    {
      id: 4,
      Sorttype: "-purchase_date",
      name: 'purchase_date(Descending)',
      Ascending: false,
    },
    {
      id: 5,
      Sorttype: "status",
      name: t("orderSortModal.orderStatusAsc"),
      Ascending: true,
    },
    {
      id: 6,
      Sorttype: "-status",
      name: t("orderSortModal.orderStatusDesc"),
      Ascending: false,
    },
    {
      id: 7,
      Sorttype: "amount",
      name: t("orderSortModal.totalAmountAsc"),
      Ascending: true,
    },
    {
      id: 8,
      Sorttype: "-amount",
      name: t("orderSortModal.totalAmountDesc"),
      Ascending: false,
    },
  ];

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setStoreName(localStorage.getItem(STORAGE_CONSTANTS.STORE_NAME));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
    setStoreLogo(localStorage.getItem(STORAGE_CONSTANTS.STORE_LOGO));
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   debouncedHandleSearch(searchText);
  // }, [searchText]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup
    };
  }, []);

  const handleSearchTextChange = async (input: string) => {
    setValue(input);
    if (input) {
      const payload = {
        store_based: "true",
        is_search: "true",
        product_name: input,
      };

      try {
        const response = await Search_Store_Orders_Suggestions(payload);
        if (response && response.status === 200) {
          const formattedStores = response.data.order_id_suggestions.map(
            (order: any) => ({
              id: order.id,
              name: `Order: ${order.id}`,
            })
          );
          setSuggestions(formattedStores);
          console.log(
            "Formatted Suggestions (post API call):",
            formattedStores
          );
        } else {
          console.log("Error fetching Store Product Details");
        }
      } catch (error) {
        console.log("Error fetching Store Product Details", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (suggestions.length > 0) {
      setFilteredSuggestions(suggestions);
      setShowSuggestions(suggestions.length > 0);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  }, [value, suggestions]);

  const handleSuggestionClick = (suggestion: Suggestion) => {
    setValue(suggestion.name);
    setShowSuggestions(false);
    fetchSearchOrders(suggestion.id);
  };

  useEffect(() => {
    if (searchText) {
      fetchSearchOrders("");
    } else {
      handleAPI();
    }
  }, [storeId, currentPage]);

  const handleAPI = async () => {
    try {
      setLoading(true);
      const response = await Order_list(userToken, currentPage);
      if (response && response.status === 200) {
        // console.log(response.data, "order---------->");
        setProduct(response.data.order_list);
        setPages(response.data.count);
      } else {
        console.log("Error fetching Order details");
      }
    } catch (error) {
      console.log("Error fetching Order details", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchOrders = async (text: any) => {
    try {
      setLoading(true);
      const payload = {
        product_name: text ? text : searchText,
        store_based: true,
      };
      const response = await Search_Store_Orders(payload, currentPage);
      if (response && response.status === 200) {
        setProduct(response.data.user_order_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = async (item: any) => {
    try {
      setLoading(true);
      const response = await Sort_Order_list(item?.Sorttype);
      if (response && response.status === 200) {
        setProduct(response.data.order_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (storeId) {
  //     fetchStoreList()
  //   }
  // }, [storeId])

  // const fetchStoreList = async () => {
  //   try {
  //     const response = await Store_Product_Details(storeId);
  //     if (response && response.status === 200) {
  //       // console.log(response.data.store_product_list[0].id);
  //       setProduct(response.data.store_product_list)
  //     } else {
  //       console.log("Error fetching Store Product Details");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching Store Product Details", error);
  //   }
  // };

  const handleClick = (id: any) => {
    localStorage.setItem(STORAGE_CONSTANTS.ORDER_ID, id);
    navigate("/view-order");
  };

  // console.log(storeName);
  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const fetchStoreOrderDetails = async (orderId: string) => {
    try {
      setLoading(true);
      const response = await getOrderDetails(orderId);

      console.log("API Response:", response); // Debugging: Check the structure of the response

      // Check if response is valid and has a product_list with items
      const { product_list } = response || {};
      if (product_list?.length > 0) {
        setOrderDetails(response);

        // Extract product IDs and store them in state
        const ids: (number | undefined)[] = product_list.map(
          (product: { product: { id: number } }) => product.product.id
        ); // Access ID through the nested product object
        console.log("Extracted Product IDs:", ids); // Debugging: Log extracted IDs

        // Check for undefined IDs and log a warning if found
        const undefinedIds = ids.filter(
          (id): id is undefined => id === undefined
        ); // Specify the type of id
        if (undefinedIds.length > 0) {
          console.warn(
            "Warning: Some product IDs are undefined.",
            undefinedIds
          );
        }

        // Filter out undefined IDs before updating state
        const validIds = ids.filter((id): id is number => id !== undefined); // Type guard to ensure only numbers are kept
        setProductIds(validIds); // Store product IDs in state
      } else {
        console.warn("No products found in the order details."); // Warning for empty product_list
        setError("No products found in the order details.");
      }
    } catch (error: any) {
      console.error("Error fetching Order Details:", error?.message || error); // Log the error message
      setError("Error fetching order details."); // Generic error message
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchStoreOrderDetails(orderId);
    }
  }, [orderId]);

  const handleOpenUpdateOrderModal = (
    orderId: string,
    currentStatus: string,
    deliveryMethod: string
  ) => {
    setCurrentOrderId(orderId);
    setOrderId(orderId); // Set the orderId for fetching details
    setDeliveryMethod(deliveryMethod);

    setCurrentStatus(currentStatus); // Store the product ID
    console.log("yur order id is ", orderId);
    console.log("yur product status is ", currentStatus);
    console.log("yur delivery method is ", deliveryMethod);
    setShowUpdateOrderModal(true);

  };

  const handleCloseUpdateOrderModal = () => {
    setShowUpdateOrderModal(false);
    resetForm(); // Reset form fields
    // Reset current product ID and status
    setCurrentOrderId(null); // Reset the product ID
    setCurrentStatus(null); // Reset the current status
  };
  const StoreOrderStatusMapping = (status: string) => {
    switch (status.toUpperCase()) {
      case "New":
      case "NEW":
        return "initial_status";
      case "Pending":
      case "PENDING":
        return "";
      case "Processing":
      case "PROCESSING":
      case "PARTIALLY_PROCESSING":
        if (deliveryMethod === "DELIVERY") {
          return "processing_status_shipping";
        } else if (deliveryMethod === "PICKED_UP") {
          return "processing_status_pickup";
        }
        return "";
      case "Complete":
      case "COMPLETE":
        return "";
      case "Delivered":
      case "DELIVERED":
        return "";
      case "Cancelled":
        return "";
      case "READY_TO_PICK":
      case "READY TO PICK":
        return "ready_to_picked";
      case "READY_TO_SHIP":
      case "READY TO SHIP":
        return "ready_to_shipped";
      case "OUT_FOR_DELIVERY":
      case "OUT FOR DELIVERY":
        return "out_for_delivery";
      case "Shipped":
      case "SHIPPED":
        return "shipped_status";
      case "Return":
        return "";
      case "Refunded":
      case "REFUNDED":
        return "";
      default:
        return "";
    }
  };

  const handleGetOrderDetailsStatusList = async (): Promise<void> => {
    try {
      // Fetch order details status list
      const response: any = await fetchOrderDetailsStatus();
      console.log("response-->>", response);
      console.log(currentStatus, "is Current status");

      // Check if the response is valid and has status_list
      if (
        response?.status &&
        response?.status_list &&
        Object.keys(response.status_list).length > 0
      ) {
        // Check if currentStatus is not null before mapping
        if (currentStatus) {
          const mappedStatusKey = StoreOrderStatusMapping(currentStatus);

          if (mappedStatusKey) {
            const dropdownData = response.status_list[mappedStatusKey] || [];
            const formattedDropdownData: DropdownOption[] = dropdownData.map(
              (status: string) => ({
                label: status,
                value: status,
              })
            );

            console.log("formattedDropdownData-->>", formattedDropdownData);
            setStatusList(formattedDropdownData);
          } else {
            setStatusList([]); // Empty list if no key is mapped
          }
        } else {
          console.warn("Current status is null, cannot map status key.");
          setStatusList([]); // Empty list if currentStatus is null
        }
      } else {
        setStatusList([]); // Empty list if response isn't valid
      }
    } catch (error) {
      console.error("Error fetching order status list:", error);
    }
  };

  // Call the API when the component mounts or when `currentStatus` changes
  useEffect(() => {
    handleGetOrderDetailsStatusList();
  }, [currentStatus]);

  // Handle status selection change
  const handleStatusChange = async (e: React.ChangeEvent<any>) => {
    const value = (e.target as HTMLSelectElement).value; // Cast target to HTMLSelectElement
    setSelectedStatus(value); // Use the value from the select element
    if (value === "Cancelled") {
      // Check if the selected status is 'Cancelled'
      try {
        const response = await fetchSellerCancellationReasons(); // Fetch cancellation reasons
        console.log("API response:", response); // Log response for debugging

        if (response && response.cancel_reason_list) {
          setReasonOptions(response.cancel_reason_list); // Populate reasons
        } else {
          setReasonOptions([]); // Reset reasons if not available
        }
      } catch (error) {
        console.error("Error fetching cancellation reasons:", error); // Handle error
        setReasonOptions([]); // Reset reasons on error
      }
    } else {
      setCancellationReason(null); // Reset cancellation reason if status is not 'Cancelled'
      setReasonOptions([]); // Reset reasons
    }
  };

  // Handle form reset
  const resetForm = () => {
    setOrderStatus(null);
    setCancellationReason(null);
    setOtherCancelReason(null);
    setReasonOptions([]);
    setSelectedStatus(null);
  };
  const handleUpdateOrder = async () => {
    console.log("order id -", currentOrderId);
    console.log("cancellation Reason -", cancellationReason);
    console.log("other Cancel Reason -", otherCancelReason);
    console.log("orderStatus -", selectedStatus);

    // Validate selected status
    if (!selectedStatus) {
      toast.error("Please select an order status");
      return;
    }

    // Validate current order ID
    if (!currentOrderId) {
      toast.error("Order information is incomplete");
      return;
    }

    // Prepare the data payload based on the selected status
    const data: any = {
      order_id: currentOrderId!, // Ensure currentOrderId is defined
      order_status: selectedStatus,
      product_id: productIds.join(","), // Join product IDs as a comma-separated string
    };

    // Handle cancellation reasons if status is 'Cancelled'
    if (selectedStatus === "Cancelled") {
      // Validate cancellation reason
      if (
        !cancellationReason ||
        (cancellationReason === "Other Reason" && !otherCancelReason)
      ) {
        toast.error("Please provide a cancellation reason");
        return;
      }
      data.cancel_reason = cancellationReason; // Add cancellation reason to the payload
      if (cancellationReason === "Other Reason") {
        data.other_reason = otherCancelReason; // Include other cancellation reason if applicable
      }
    }

    try {
      // Call the function to update the order with the prepared data
      await sellerCancelLineItem(
        data.order_id,
        data.product_id,
        data.order_status,
        data.cancel_reason,
        data.other_reason
      );
      toast.success("Order status updated successfully!"); // Show success message
      await handleAPI(); // Call the API to refresh order status if needed
    } catch (error) {
      toast.error("Failed to update the order"); // Show error message
    }

    handleCloseUpdateOrderModal(); // Close the modal after operation
  };

  return (
    <>
      <Section className="orderList" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    {crumb.link ? (
                      <Link
                        to={crumb.link}
                        style={{
                          color:
                            index === 0 ? "var(--primary-color)" : "#BEB8B8",
                          fontFamily: "var(--primary-font-medium)",
                          fontSize: 16,
                        }}
                      >
                        {crumb.text}
                      </Link>
                    ) : (
                      <span
                        style={{
                          color:
                            index === 0 ? "var(--primary-color)" : "#BEB8B8",
                          fontFamily: "var(--primary-font-medium)",
                          fontSize: 16,
                        }}
                      >
                        {crumb.text}
                      </span>
                    )}
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <div style={styles.searchRow} ref={dropdownRef}>
            <input
              className="mr-sm-2 search-input order-input"
              placeholder="Search..."
              value={value}
              onChange={(e) => handleSearchTextChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  fetchSearchOrders(value); // Trigger search on Enter key
                }
              }}
              autoFocus
            />
            <Button
              variant="outline-primary search-btn order-search-btn"
              onClick={() => fetchSearchOrders(value)}
            >
              <FaSearch />
            </Button>

            {showSuggestions && suggestions.length > 0 && (
              <div className="suggestions-dropdown">
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.id}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Container>
      </Section>
      <Section className="orders" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <div className="d-flex align-items-center justify-content-center flex-row">
              <div style={styles.shopImageWrap} className="mx-2">
                <Image
                  src={
                    storeLogo !== "null" ? `${base_url}${storeLogo}` : shopImage
                  }
                  style={styles.shopImage}
                />
              </div>
              <div style={styles.shopNameWrap}>
                <h3 style={styles.shopName}>{storeName}</h3>
              </div>
            </div>
            {/* <div style={styles.normalText}>01/01/2024 to 01/01/2024</div> */}
            <div
              className="d-flex align-items-center justify-content-between flex-row mt-3"
              style={{ position: "relative" }}
            >
              <div className="">
                <h3 style={styles.orderTitle}>{t("orderList.orders")}</h3>
                {/* <p className="mb-0" style={styles.orderStatus}> {t("orderList.status")} : Completed</p> */}
              </div>
              <div style={styles.normalText} className="">
                {t("orderList.noOfOrders")} : {product.length}
              </div>
              <div ref={target}>
                <Button
                  style={styles.btnSort}
                  variant="outline-primary"
                  onClick={() => setShowSortPopover(!showSortPopover)}
                >
                  <Image src={Sort} className="sortImage" />
                </Button>
                <Overlay
                  show={showSortPopover}
                  target={target.current}
                  placement="bottom"
                  containerPadding={20}
                  container={document.body}
                  onHide={handleOverlayClose}
                  rootClose
                >
                  <Popover id="popover-contained">
                    <Popover.Body>
                      <ul
                        className="sortPopover"
                        style={{ listStyleType: "none", padding: 0 }}
                      >
                        {sortData &&
                          sortData.map((ele) => {
                            return (
                              <li>
                                <Button
                                  variant="link"
                                  onClick={() => handleSort(ele)}
                                >
                                  {ele?.name}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>

            <div style={styles.orderTableWrap}>
              {loading && (
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner animation="grow" variant="primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
              <div className="table-responsive">
                <Table hover style={styles.orderTable} className="productTable">
                  <tbody>
                    {!loading && product.length === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={styles.noProduct}>
                          <Image
                            src={noProduct}
                            className="mx-2"
                            style={{ width: 32, height: 32 }}
                          />{" "}
                          {t("userProfile.noOrdersFound")}
                        </h3>
                      </div>
                    ) : (
                      !loading &&
                      product.length !== 0 &&
                      product &&
                      product.map((item: any) => {
                        return (
                          <tr
                            key={item.id}
                          // onClick={() => handleClick(item.id)}
                          // style={{ cursor: "pointer" }}
                          >
                            <td className="hide-xs">
                              {" "}
                              <input
                                type="radio"
                                name="productSelect"
                                style={styles.orderRadio}
                              />
                            </td>
                            <td>
                              <div style={styles.orderImageWrap}>
                                <Image
                                  src={`${base_url}/${item?.order_details[0]?.product?.product_image}`}
                                  style={styles.orderImage}
                                />
                                {item?.order_details.length > 1 && (
                                  <Badge
                                    style={{
                                      position: "absolute" as React.CSSProperties["position"],
                                      top: 0,
                                      right: 0,
                                    }}
                                    bg="danger"
                                  >
                                    + {item?.order_details.length - 1} items
                                  </Badge>
                                )}
                              </div>

                            </td>
                            <td style={styles.orderInfo}>{item.id}</td>
                            <td style={styles.orderInfo}>
                              {String(item.purchase_date).split("T")[0]}
                            </td>
                            <td style={styles.orderInfo}>रु {item.total_amount}</td>
                            <td style={styles.completed}>
                              {item.store_order_status.status}
                            </td>
                            <td style={styles.orderInfo}>
                              <Button
                                className="btnBlue"
                                onClick={() =>
                                  handleOpenUpdateOrderModal(
                                    item.id,
                                    item.store_order_status.status,
                                    item.delivery_method

                                  )
                                } // Pass the correct status
                                disabled={
                                  !item.store_order_status.can_change ||
                                  item.status === "Delivered" ||
                                  item.status === "Cancelled"
                                } // Disable condition
                              >
                                Update Order
                              </Button>

                              {/* Update Order Modal */}
                              <Modal
                                show={showUpdateOrderModal}
                                onHide={handleCloseUpdateOrderModal}
                                className="cancelOrderModal"
                                centered
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Update Order Status</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Form>
                                    <Form.Group controlId="orderStatus">
                                      <Form.Label>
                                        Select Order Status:
                                      </Form.Label>
                                      <Form.Control
                                        as="select"
                                        value={selectedStatus || ""}
                                        onChange={(e) => handleStatusChange(e)} // Remove the event typing here
                                      >
                                        <option value="">Select a status</option>
                                        {statusList.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>

                                    {selectedStatus === "Cancelled" && (
                                      <Form.Group
                                        controlId="cancellationReason"
                                        className="mt-2"
                                      >
                                        <Form.Label>
                                          Reason for Cancellation:
                                        </Form.Label>
                                        <Form.Control
                                          as="select"
                                          value={cancellationReason || ""}
                                          onChange={(e) =>
                                            setCancellationReason(e.target.value)
                                          } // Update cancellation reason
                                        >
                                          <option value="">
                                            Select a reason
                                          </option>
                                          {reasonOptions.length > 0 ? (
                                            reasonOptions.map((reason, index) => (
                                              <option key={index} value={reason}>
                                                {reason}
                                              </option>
                                            ))
                                          ) : (
                                            <option value="">
                                              No reasons available
                                            </option>
                                          )}
                                        </Form.Control>
                                      </Form.Group>
                                    )}

                                    {cancellationReason === "Other Reason" && (
                                      <Form.Group
                                        controlId="otherCancelReason"
                                        className="mt-2"
                                      >
                                        <Form.Label>
                                          Please specify the reason:
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          value={otherCancelReason || ""}
                                          onChange={(e) =>
                                            setOtherCancelReason(e.target.value)
                                          }
                                        />
                                      </Form.Group>
                                    )}

                                    {error && (
                                      <p className="text-danger">{error}</p>
                                    )}
                                  </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    className="btnCancel"
                                    variant="secondary"
                                    onClick={handleCloseUpdateOrderModal}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    className="btnBlue"
                                    variant="primary"
                                    onClick={handleUpdateOrder}
                                  >
                                    Update Order
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </td>
                            {/* <td style={styles.orderInfo}>411052</td> */}
                            <td style={styles.orderInfo}>
                              <FaAngleRight
                                onClick={() => handleClick(item.id)}
                                style={{ cursor: "pointer", fontSize: 24 }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
              {!loading && pages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    float: "right",
                  }}
                  className="mobile-float-none"
                >
                  {(isSearchResult ? pages <= 10 : true) && (
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        {/* Previous Button */}
                        <li
                          className={`page-item ${currentPage === 1 ? "disabled" : ""
                            }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              setCurrentPage(Math.max(currentPage - 1, 1))
                            }
                          >
                            {t("userProfile.previous")}
                          </a>
                        </li>

                        {/* Page Numbers */}
                        {Array.from({ length: pages }).map((_, index) => (
                          <li
                            className={`page-item ${currentPage === index + 1 ? "active" : ""
                              }`}
                            key={index}
                          >
                            <a
                              className="page-link"
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))}

                        {/* Next Button */}
                        <li
                          className={`page-item ${currentPage === pages ? "disabled" : ""
                            }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              setCurrentPage(Math.min(currentPage + 1, pages))
                            }
                          >
                            {t("userProfile.next")}
                          </a>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              )}

            </div>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default OrderList;

// const SuggestionList:React.FC<{item:any}> = ({item}) => {
//   console.log("item--1018-", item)
//   return <div>
//   <span>{item.id}</span>
//   <span>{item.name}</span>

// </div>
// }
